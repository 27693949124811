import React from "react";
import Grid from "@material-ui/core/Grid";
import { IMG_PMLOGO } from "../../PMConstants/PMImageConstants";
import { Helmet } from "react-helmet";

const QualityPolicy = () => {
  window.scrollTo(0, 0);
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://softspot.ai/quality-policy" />
      </Helmet>
      <div className="page-container">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <div className="content-wrapper text-content-wrapper">
              <div className="">
                <p align="center"><h1>Quality Policy Statement</h1></p>
                <p>PediaMetrix develops and markets safe and effective pediatric care devices using AI and computer vision
                    technology to improve pediatric care measurements for parents, children, and providers at the point-ofcare level. PediaMetrix ensures the effectiveness of our quality management system through a
                    committed and engaged workforce, the establishment of quality objectives focused on meeting
                    customer expectations, operational excellence, robust design & development, and a commitment to
                    continuous improvement. Quality objectives will be determined by PediaMetrix management to ensure
                    compliance with regulatory guidelines.</p>
                  </div>
              </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default QualityPolicy;
