const apiEndPoints = {
  SIGNUP: "/user/sign-up",
  REGISTER_BABY: "/user/register-baby",
  SIGNIN: "/user/sign-in",
  PHYSICIAN_LIST: "/physicians",
  UPDATE_BABY: "/user/update-baby",
  VERIFY_EMAIL: "/email/verify",
  UPLOAD_PHOTO: "/report/upload",
  PREPARE_REPORT: "/report/prepare",
  FINALIZE_REPORT: "/report/finalize",
  GET_USER_DETAILS: "/user/profile",
  GET_REPORT_LIST: "/report/list",
  GET_PATIENT_LIST: "/patient/list",
  ADD_PATIENT: "/patient/add",
  UPDATE_PATIENT: "/patient/update",
  FORGOT_PASSWORD: "/user/password/recover",
  RESET_PASSWORD: "/user/password/reset",
  RESEND_VERIFICATION_LINK: "/email/verify",
  GET_RESULTS: "/report/results",
  DOWNLOAD_RESULT: "/report/_id_/download",
  CHANGE_PASSWORD: "/user/password/change",
  UPDATE_PROFILE: "/user/profile",
  GET_PROFILE_PIC: "/user/profile-photo",
  ADD_BABY: "/user/add-baby",
  SIGN_OUT: "/user/sign-out",
  GET_REMINDERS: "/user/reminders",
  GET_UPLOADS: "/report/my-uploads",
  GET_NOTIFICATION_COUNT: "/user/notifications-count",
  GET_NOTIFICATION_LIST: "/user/notifications",
  PREVIEW_UPLOAD: "/report/attachment",
  LOAD_THUMBNAIL: "/report/thumb",
  EMAIL_CHAT_MESSAGES: "/chat/messages",
  UPDATE_CHILD: "/user/update-child",
  ADD_PHYSICIAN_USER: "/physician/add-user",
  ADD_NURSE: "/physician/nurse/add",
  REMOVE_NURSE: "/physician/nurse/remove",
  NURSE_LIST: "/physician/nurse/list",
  STAFF_LIST: "/physician/list",
  PHYSICIAN_STAFF_LIST: "/physician/staff/list",
  READ_NOTIFICATIONS: "/user/read-notifications",
};

export default apiEndPoints;
