import React from "react";
import Grid from "@material-ui/core/Grid";
import { IMG_PMLOGO } from "../../PMConstants/PMImageConstants";
import { Helmet } from "react-helmet";

const Disclaimer = () => {
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://softspot.ai/terms-and-conditions" />
      </Helmet>
      <div className="page-container">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <div className="content-wrapper text-content-wrapper">
              <div className="">
                <ul className="disclaimer-content-ul">
                  <li>
                    All your information will be kept completely confidential. The
                    studies and reports that are made public as part of
                    publications or promotional materials will never divulge any
                    information that will make it possible to identify you or your
                    child or patients. Moreover, all of the data will be kept in a
                    secure location; and only affiliated people will have access
                    to them. By posting, uploading, inputting, providing or
                    submitting materials including photos and videos to our
                    website (Collectively called Submission), you are granting
                    PediaMetrix, its affiliated companies, and necessary
                    sub-licensees permission to use your Submission in connection
                    with the operation of their businesses including, without
                    limitation, the rights to: copy, distribute, transmit,
                    publicly display, publicly perform, reproduce, edit, translate
                    and reformat your Submission as long as you or your child or
                    your patients are not easily identifiable.
                  </li>
                  <li>
                    Please be aware that we do not intend to make a diagnosis, or
                    propose a treatment. In addition, our measurements may be
                    different from measurements done by other companies such as
                    helmet clinics as we use our own proprietary methods (numbers
                    provided by two different clinics do not necessarily match
                    either). What is important is the consistency of measurements
                    and quantitative progress compared to the starting point.
                  </li>
                  <li>
                    The results and consultation provided to you as part of this
                    service is for your own information only. It does not replace
                    doctor's advice. We recommend you consult your child or
                    patient's clinician for any concerns.
                  </li>
                </ul>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Disclaimer;
