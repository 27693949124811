const fieldName = {
  FIRSTNAME: "first_name",
  LASTNAME: "last_name",
  EMAIL: "email",
  PASSWORD: "password",
  OLD_PASSWORD: "old_password",
  CONFIRM_PASSWORD: "confirm_password",
  MOBILE_NUMBER: "mobile_number",
  DOCTOR_NAME: "doctor_name",
  CHILD_NAME: "child_name",
  CHILD_DOB: "child_dob",
  RELATIONSHIP: "relationship",
  PHYSICIAN_ID: "physician_identifier",
  PATIENT_NAME: "patient_name",
  PATIENT_GENDER: "patient_gender",
};

export default fieldName;
