const IMG_PMLOGIN = require("../PMAssets/PMImages/pm-img-login.svg");
const IMG_PMLOCK = require("../PMAssets/PMImages/pm-lock.svg");
const IMG_PMUSER = require("../PMAssets/PMImages/pm-user.svg");
const IMG_PMLOGO = require("../PMAssets/PMImages/pm_softspot_logo.svg");
const IMG_BACK = require("../PMAssets/PMImages/pm_back.svg");
const IMG_PM_UP_ARROW_GREY = require("../PMAssets/PMImages/pm_up_arrow_grey.svg");
const IMG_PM_UP_ARROW_ORANGE = require("../PMAssets/PMImages/pm_up_arrow_orange.svg");
const IMG_PM_CHILD_SIGNUP = require("../PMAssets/PMImages/pm_signup.svg");
const IMG_PM_IMAGE_ICON = require("../PMAssets/PMImages/pm_image_icon.svg");
const IMG_PM_FILE_ERROR = require("../PMAssets/PMImages/pm_some_error.svg");
const IMG_PM_CLOSE = require("../PMAssets/PMImages/pm_close.svg");
const IMG_PM_WORKFLOW_CIRCULAR = require("../PMAssets/PMImages/pm_circular_workflow.svg");
const IMG_PM_WORKFLOW_SQUARE = require("../PMAssets/PMImages/pm_square_workflow.svg");
const IMG_PM_CUSTOMER_SUPPORT = require("../PMAssets/PMImages/pm_customer_support.png");
const IMG_PM_DOWNLOAD = require("../PMAssets/PMImages/pm-download.svg");
const IMG_PM_SEARCH = require("../PMAssets/PMImages/pm-search.svg");
const IMG_PM_SIGNUP_CONFIRMED = require("../PMAssets/PMImages/pm-signup-confirmed.png");
const IMG_PM_CHAT_ICON = require("../PMAssets/PMImages/pm_chat_icon.svg");
const IMG_PM_PLAY = require("../PMAssets/PMImages/pm_play_icon.png");

const PM_LOGO = require("../PMAssets/PMImages/pm_logo.svg");
const IMG_PM_APPSTORE = require("../PMAssets/PMImages/pm_appstore.svg");
const IMG_PM_PLAYSTORE = require("../PMAssets/PMImages/pm_playstore.svg");
const IMG_PM_TESTIMONIAL1 = require("../PMAssets/PMImages/pm_testimonial_1.svg");
const IMG_PM_TESTIMONIAL2 = require("../PMAssets/PMImages/pm_testimonial_2.svg");
const IMG_PM_TESTIMONIAL3 = require("../PMAssets/PMImages/pm_testimonial_3.svg");
const IMG_PM_TESTIMONIAL_ICON = require("../PMAssets/PMImages/pm_testimonial_icon.svg");
const IMG_PM_MOBILE_ILLUSTRATION = require("../PMAssets/PMImages/pm_mobile_in_hand_illustration.jpg");
const IMG_PM_CONTACTUS = require("../PMAssets/PMImages/pm_contactus.svg");
const IMG_PM_LINKEDIN_ICON = require("../PMAssets/PMImages/pm_icon_linkedin.svg");
const IMG_PM_NEWS_DEMO_IMAGE = require("../PMAssets/PMImages/pm_news_demo.svg");
const IMG_PM_FOOTER_IMAGE = require("../PMAssets/PMImages/pm_footer_image.svg");
const IMG_PM_BG_SPONSORS = require("../PMAssets/PMImages/pm_bg_sponsers.svg");
const IMG_PM_BG_HOW_IT_WORKS = require("../PMAssets/PMImages/pm_bg_how_it_works.svg");
//const IMG_PM_MISSION_IMAGE = require("../PMAssets/PMImages/pm_mission_image.svg");
const IMG_PM_MISSION_IMAGE = require("../PMAssets/PMImages/pm_mission_image.jpg");

const IMG_PM_FDA = require("../PMAssets/PMImages/fda_cleared.png");
const IMG_PM_HIPAA = require("../PMAssets/PMImages/hipaa.png");
const DEMO_PAGE_IMAGE = require("../PMAssets/PMImages/demo_page_image.png");
const SHOPPING_CART_IMAGE = require("../PMAssets/PMImages/cart.png");
const BUY_IMAGE = require("../PMAssets/PMImages/buy.png");
const PHY_REIMBURSEMENT = require("../PMAssets/PMImages/physician_reimbursement.png");

const IMG_PM_HC_BABYHEAD1 = require("../PMAssets/PMImages/pm_hc_baby_head_1.png");
const IMG_PM_HC_BABYHEAD2 = require("../PMAssets/PMImages/pm_hc_baby_head_2.png");
const IMG_PM_HC_BABYHEAD3 = require("../PMAssets/PMImages/pm_hc_baby_head_3.png");
const IMG_PM_HC_PRODUCT_1 = require("../PMAssets/PMImages/pm_hc_products1.png");
const IMG_PM_HC_PRODUCT_2 = require("../PMAssets/PMImages/pm_hc_products2.png");
const IMG_PM_HC_PRODUCT_3 = require("../PMAssets/PMImages/pm_hc_products3.png");
const IMG_PM_HC_PRODUCT_4 = require("../PMAssets/PMImages/pm_hc_products4.png");
const IMG_PM_HC_PRODUCT_5 = require("../PMAssets/PMImages/pm_hc_products5.png");
const IMG_PM_HC_PRODUCT_6 = require("../PMAssets/PMImages/pm_hc_products6.png");
const IMG_PM_CI_PROSPECTIVE = require("../PMAssets/PMImages/CI_prospective.jpg");
const IMG_PM_CI_RETROSPECTIVE = require("../PMAssets/PMImages/CI_retrospective.png");
const IMG_PM_CVAI_PROSPECTIVE = require("../PMAssets/PMImages/CVAI_prospective.jpg");
const IMG_PM_CVAI_RETROSPECTIVE = require("../PMAssets/PMImages/CVAI_retrospective.png");

const IMG_PM_HC_MEASURE_1 = require("../PMAssets/PMImages/pm_hc_physician_measure_1.png");
const IMG_PM_HC_MEASURE_2 = require("../PMAssets/PMImages/pm_hc_physician_measure_2.png");
const IMG_PM_HC_MEASURE_3 = require("../PMAssets/PMImages/pm_hc_physician_measure_3.png");
const IMG_PM_HC_MEASURE_4 = require("../PMAssets/PMImages/pm_hc_physician_measure_4.png");
const IMG_PM_HC_RESULT_1 = require("../PMAssets/PMImages/pm_hc_result_interpretation_1.jpg");
const IMG_PM_HC_RESULT_2 = require("../PMAssets/PMImages/pm_hc_result_interpretation_2.png");
const IMG_PM_HC_ACCURACY = require("../PMAssets/PMImages/pm_hc_accuracy.png");
const IMG_REVIEW = require("../PMAssets/PMImages/review.jpg");

const IMG_FACEBOOK = require("../PMAssets/PMImages/facebook.png");
const IMG_LINKEDIN = require("../PMAssets/PMImages/linkedin.png");
const IMG_YOUTUBE = require("../PMAssets/PMImages/youtube.png");
const IMG_TWITTER = require("../PMAssets/PMImages/twitter.png");


export {
  PM_LOGO,
  IMG_PM_MISSION_IMAGE,
  IMG_PM_APPSTORE,
  IMG_PM_PLAYSTORE,
  IMG_PM_TESTIMONIAL1,
  IMG_PM_TESTIMONIAL2,
  IMG_PM_TESTIMONIAL3,
  IMG_PM_TESTIMONIAL_ICON,
  IMG_PM_MOBILE_ILLUSTRATION,
  IMG_PM_CONTACTUS,
  IMG_PM_LINKEDIN_ICON,
  IMG_PM_NEWS_DEMO_IMAGE,
  IMG_PM_FOOTER_IMAGE,
  IMG_PM_BG_SPONSORS,
  IMG_PM_BG_HOW_IT_WORKS,
  IMG_PM_PLAY,
  IMG_PM_DOWNLOAD,
  IMG_PM_SEARCH,
  IMG_PM_WORKFLOW_CIRCULAR,
  IMG_PM_WORKFLOW_SQUARE,
  IMG_PM_CUSTOMER_SUPPORT,
  IMG_PM_CLOSE,
  IMG_PM_CHILD_SIGNUP,
  IMG_PMLOGIN,
  IMG_PMLOCK,
  IMG_PMUSER,
  IMG_PMLOGO,
  IMG_PM_CHAT_ICON,
  IMG_BACK,
  IMG_PM_UP_ARROW_GREY,
  IMG_PM_UP_ARROW_ORANGE,
  IMG_PM_IMAGE_ICON,
  IMG_PM_FILE_ERROR,
  IMG_PM_SIGNUP_CONFIRMED,
  IMG_PM_FDA,
  IMG_PM_HIPAA,
  DEMO_PAGE_IMAGE,
  SHOPPING_CART_IMAGE,
  BUY_IMAGE,
  IMG_PM_HC_BABYHEAD1,
  IMG_PM_HC_BABYHEAD2,
  IMG_PM_HC_BABYHEAD3,
  IMG_PM_HC_PRODUCT_1,
  IMG_PM_HC_PRODUCT_2,
  IMG_PM_HC_PRODUCT_3,
  IMG_PM_HC_PRODUCT_4,
  IMG_PM_HC_PRODUCT_5,
  IMG_PM_HC_PRODUCT_6,
  IMG_PM_CI_PROSPECTIVE,
  IMG_PM_CI_RETROSPECTIVE,
  IMG_PM_CVAI_PROSPECTIVE,
  IMG_PM_CVAI_RETROSPECTIVE,
  IMG_PM_HC_MEASURE_1,
  IMG_PM_HC_MEASURE_2,
  IMG_PM_HC_MEASURE_3,
  IMG_PM_HC_MEASURE_4,
  IMG_PM_HC_RESULT_1,
  IMG_PM_HC_RESULT_2,
  IMG_PM_HC_ACCURACY,
  IMG_REVIEW,
  IMG_FACEBOOK,
  IMG_TWITTER,
  IMG_YOUTUBE,
  IMG_LINKEDIN,
  PHY_REIMBURSEMENT
};
