import actions from "./PMActions";
import axiosInstance from "../PMCommon/PMApiHandler";
import apiEndPoints from "../PMCommon/PMApiEndPoints";

const initialState = {
  isLoading: false,
  isShowNotification: false,
  notificationVariant: "success",
  notificationMessage: "",
  isDisplayUserChat: false,
  newMessageCount: 0,
  isUserQuerySent: false,
  userQueryError: null,
};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.REDIRECT_USER:
      return {
        ...state,
        isLoading: false,
        isShowNotification: false,
        notificationVariant: "success",
        notificationMessage: "",
        isDisplayUserChat: false,
        newMessageCount: 0,
        isUserQuerySent: false,
        userQueryError: null,
      };
    case actions.SHOW_LOADER:
      return {
        ...state,
        isLoading: true,
      };
    case actions.HIDE_LOADER:
      return {
        ...state,
        isLoading: false,
      };
    case actions.DISPLAY_NOTIFICATION:
      return {
        ...state,
        isShowNotification: action.data.isDisplay || false,
        notificationVariant: action.data.notificationVariant,
        notificationMessage: action.data.notificationMessage,
      };
    case actions.DISPLAY_USER_CHAT:
      return {
        ...state,
        isDisplayUserChat: action.data,
        newMessageCount: 0,
      };
    case actions.INCREMENT_MESSAGE_COUNT:
      return {
        ...state,
        newMessageCount: state.newMessageCount + 1,
      };
    case actions.EMAIL_CHAT_MESSAGES_SUCCESS:
      return {
        ...state,
        isUserQuerySent: true,
        userQueryError: null,
      };
    case actions.EMAIL_CHAT_MESSAGES_ERROR:
      return {
        ...state,
        isUserQuerySent: false,
        userQueryError:
          action.data && action.data.data ? action.data.data : null,
      };
    case actions.EMAIL_CHAT_MESSAGES_RESET:
      return {
        ...state,
        isUserQuerySent: false,
        userQueryError: null,
      };
    default:
      return state;
  }
};

const displayUserChat = (isDisplay) => {
  return (dispatch) => {
    dispatch({
      type: actions.DISPLAY_USER_CHAT,
      data: isDisplay,
    });
  };
};

const incrementNewMessageCount = (count) => {
  return (dispatch) => {
    dispatch({
      type: actions.INCREMENT_MESSAGE_COUNT,
      data: count,
    });
  };
};

const setDisplayNotification = (
  isDisplay,
  notificationVariant,
  notificationMessage
) => {
  return (dispatch) => {
    dispatch({
      type: actions.DISPLAY_NOTIFICATION,
      data: { isDisplay, notificationVariant, notificationMessage },
    });
  };
};

const getUserDetails = () => {
  return (dispatch) => {
    axiosInstance
      .get(apiEndPoints.GET_USER_DETAILS)
      .then((data) => {
        dispatch({
          type: actions.USER_DETAILS_SUCCESS,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.USER_DETAILS_ERROR,
          data: error,
        });
      });
  };
};

const emailUnseenMessages = (dataObj) => {
  return (dispatch) => {
    axiosInstance
      .post(apiEndPoints.EMAIL_CHAT_MESSAGES, dataObj)
      .then((data) => {
        dispatch({
          type: actions.EMAIL_CHAT_MESSAGES_SUCCESS,
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actions.EMAIL_CHAT_MESSAGES_ERROR,
          data: error,
        });
      });
  };
};

const resetEmailSent = () => {
  return (dispatch) => {
    dispatch({
      type: actions.EMAIL_CHAT_MESSAGES_RESET,
    });
  };
};

// list all actions here
export const appActions = {
  getUserDetails,
  setDisplayNotification,
  displayUserChat,
  incrementNewMessageCount,
  emailUnseenMessages,
  resetEmailSent,
};
