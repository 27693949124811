import React from "react";
import PubNub from "pubnub";
import { Provider } from "react-redux";
import { PubNubProvider } from "pubnub-react";
import { store } from "./PMShared/PMRedux/PMStore";
import Routes from "./PMRoutes/Routes";
import config from "./config";
import "./base.scss";
import "./App.scss";

const pubnub = new PubNub({
  subscribeKey: config.pubnubSubscribeKey,
  publishKey: config.pubnubPublishKey,
  autoNetworkDetection: true,
  restore: true,
});

function App() {
  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  );
}

export default App;
