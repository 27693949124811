const config = {
  //pubnubSubscribeKey: "sub-c-4e1dfe92-0e26-11eb-a0a1-be9072d3ef6c",
  //pubnubPublishKey: "pub-c-fd173b2d-95aa-4a08-aa6d-cadbdf0503c2",
  softspotAlphaLogin: "https://www.softspot-alpha.com/softspot-login",
  softspotAlphaBeta: "https:/login.softspot.tk",
  softspotLogin: "https://login.softspot.ai/",
  softspotSignup: "https://login.softspot.ai/sign-up"

};

// Dev Env keys
// pubnubSubscribeKey: "sub-c-d6e2802e-0be0-11eb-8b70-9ebeb8f513a7",
// pubnubPublishKey: "pub-c-30a6f826-8784-430a-9d7b-0620b42d15cc",

export default config;
