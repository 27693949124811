import axios from "axios";
import apiEndPoints from "./PMApiEndPoints";
import { store } from "../PMRedux/PMStore";
import actions from "../PMRedux/PMActions";

const defaultOptions = {
  // baseURL:
  //   "http://7add36a90d24.ngrok.io/SoftSpot_Infostretch_Backend/public/api/v1/en",
  baseURL: "https://api.softspot.ai/api/v1/en",
  // baseURL: "http://3.21.68.121/api/v1/en",
  headers: {
    "Content-Type": "application/json",
  },
};

const handleBlobError = async (error) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => {
      error.response.data = JSON.parse(reader.result);
      resolve(Promise.reject(error.response));
    };

    reader.onerror = () => {
      reject(error.response);
    };

    reader.readAsText(error.response.data);
  });
};

const axiosInstance = axios.create(defaultOptions);

axiosInstance.interceptors.request.use(
  async (config) => {
    store.dispatch({
      type: actions.SHOW_LOADER,
    });

    const preLoginAPIs = [
      apiEndPoints.PHYSICIAN_LIST,
      apiEndPoints.SIGNIN,
      apiEndPoints.SIGNUP,
    ];
    if (preLoginAPIs.indexOf(config.url) > -1) {
    } else {
      if (typeof sessionStorage !== "undefined") {
        const accessToken = sessionStorage.getItem("accessToken");
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => {
    store.dispatch({
      type: actions.HIDE_LOADER,
    });
    return response;
  },
  async (error) => {
    store.dispatch({
      type: actions.HIDE_LOADER,
    });
    if (error.response && error.response.status === 401) {
      sessionStorage.clear();
      store.dispatch({
        type: actions.UPLOAD_LIST_RESET,
      });
      store.dispatch({
        type: actions.RESULT_LIST_RESET,
      });
      store.dispatch({
        type: actions.RESET_PATIENT_MANAGEMENT,
      });

      store.dispatch({
        type: actions.REDIRECT_USER,
      });
      store.dispatch({
        type: actions.RESET_LOGIN,
      });
      store.dispatch({
        type: actions.DISPLAY_NOTIFICATION_LOGIN,
        data: {
          isShowNotification: true,
          notificationVariant: "error",
          notificationMessage: error.response.data
            ? error.response.data.message
            : "You are not authorised to perform this action",
        },
      });
    } else if (error.response && error.response.status === 404) {
      // redirect to 404 page
    } else if (error.response && error.response.status === 500) {
      // handle server error
    }
    if (
      error.request.responseType === "blob" &&
      error.response.data instanceof Blob &&
      error.response.data.type &&
      error.response.data.type.toLowerCase().indexOf("json") != -1
    ) {
      const jsonError = await handleBlobError(error);
      return jsonError;
    }
    return Promise.reject(error.response);
  }
);

export default axiosInstance;
