import React, { useRef, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import { Badge } from "@material-ui/core";
import {
  IMG_PM_CUSTOMER_SUPPORT,
  IMG_PM_CHAT_ICON,
} from "../PMConstants/PMImageConstants";
import { appActions } from "../PMShared/PMRedux/PMAppActionReducer";
import UserChat from "../PMScreens/PMUserChat/PMUserChat";
import Footer from "../PMComponents/PMFooter/PMFooter";
import Header from "../PMComponents/PMHeader/PMHeader";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga"

const PublicRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const GA_TRACKING_ID = 'UA-215568703-1'

  ReactGA.initialize(GA_TRACKING_ID);

  const isDisplayUserChat = useSelector((state) => {
    return state.appReducer.isDisplayUserChat;
  });
  const newMessageCount = useSelector((state) => {
    return state.appReducer.newMessageCount;
  });
  const commonRef = useRef(null);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  const structuredDataSingle = {
    "@context": "https://schema.org",
    "@type": "LodgingBusiness",
    "name": "PediaMetrix Inc.",
    "image": "https://www.pediametrix.com/static/media/pm_logo.030e5e30.svg",
    "@id": "https://www.pediametrix.com/",
    "url": "https://www.pediametrix.com/",
    "telephone": "(240) 670-0171",
    "priceRange": "$",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "155 Gibbs St UNIT 537,",
      "addressLocality": "Rockville",
      "addressRegion": "MD",
      "postalCode": "20850",
      "addressCountry": "US"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 39.0867699,
      "longitude": -77.15177969999999
    }
  }


  // https://developers.google.com/analytics/devguides/collection/gtagjs/pages
  const pageview = url => {
    window.gtag('config', GA_TRACKING_ID, {
      page_location: url
    })
  }

  // https://developers.google.com/analytics/devguides/collection/gtagjs/events
  const event = ({ action, category, label, value }) => {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value
    })
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Helmet>
            <title> {rest.title}</title>
            <meta name="description" content={rest.description} />
            <script async src="https://www.googletagmanager.com/gtag/js?id=UA-215568703-1"></script>
            {/*<script>
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments)}
              gtag('js', new Date());
              gtag('config', 'UA-215568703-1');
            </script>*/}
            <meta name="google-site-verification" content="kSUbmVuYSvLvNB2tktk_AgHCsw-p15V8-t0EsX_X3EY" />
            <meta name="DC.title" content="PediaMetrix Inc." />
            <meta name="geo.region" content="US-MD" />
            <meta name="geo.placename" content="Rockville" />
            <meta name="geo.position" content="39.090958;-77.1703" />
            <meta name="ICBM" content="39.090958, -77.1703" />
            <script type="application/ld+json">
              {JSON.stringify(structuredDataSingle)}
            </script>
          </Helmet>
          <Header {...props} commonRef={commonRef} />
          <div className="">
            <Component {...props} commonRef={commonRef} />
          </div>
          <Footer />
          <>
            {/*<UserChat />
            <div className="customer-support">
              <Badge
                badgeContent={newMessageCount}
                color="primary"
                onClick={() =>
                  dispatch(appActions.displayUserChat(!isDisplayUserChat))
                }
              >
                <img src={IMG_PM_CHAT_ICON} alt="" />
              </Badge>
              </div>*/}
          </>
        </>
      )}
    />
  );
};

export default PublicRoute;
