import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { Helmet } from "react-helmet";
//import "node_modules/react-modal-video/scss/modal-video.scss";
import "./../../../node_modules/react-modal-video/scss/modal-video.scss"
import ModalVideo from 'react-modal-video'
import { Carousel } from '@trendyol-js/react-carousel';
import Slider from "react-slick";
import Button from "../../PMComponents/PMButton/PMButton";
import {
  PM_LOGO,
  IMG_PM_BG_HOW_IT_WORKS,
  IMG_PM_MOBILE_ILLUSTRATION,
  IMG_PM_MISSION_IMAGE,
  IMG_PM_PLAYSTORE,
  IMG_PM_APPSTORE,
  IMG_PM_TESTIMONIAL1,
  IMG_PM_TESTIMONIAL2,
  IMG_PM_TESTIMONIAL3,
  IMG_PM_FOOTER_IMAGE,
  IMG_PM_LINKEDIN_ICON,
  IMG_PM_FDA,
  IMG_REVIEW,
  IMG_FACEBOOK, IMG_LINKEDIN, IMG_YOUTUBE, IMG_TWITTER
} from "../../PMConstants/PMImageConstants";
import "./PMDashboard.scss";
import Workflow from "../PMWorkflow/PMWorkflow";
import { Link } from "react-router-dom";
import { DialogContent } from "@material-ui/core";

const Dashboard = (props) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    // centerMode: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const [sponsorsLinks, setSponsorLinks] = useState([]);
  const [testimonialData, setTestimonialData] = useState([]);
  const [teamMembersData, setTeamMembersData] = useState([]);
  const [isOpen, setOpen] = useState(false)


  /* this.state = {
     isOpen: false
   }
   this.openModal = this.openModal.bind(this)
 }*/

  function openModal() {
    this.setState({ isOpen: true })
  }
  useEffect(() => {
    fetch(`data/sponsors.json`)
      .then((r) => r.json())
      .then((data) => {
        setSponsorLinks(data.sponsorsList);
      });
    fetch(`data/testimonial.json`)
      .then((r) => r.json())
      .then((data) => {
        setTestimonialData(data.testimonialList);
      });
    fetch(`data/teamMembers.json`)
      .then((r) => r.json())
      .then((data) => {
        setTeamMembersData(data.teamMembersList);
      });
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://softspot.ai" />
        {/*Start of HubSpot Embed Code */}
        {/*<script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/3289469.js"></script>*/}
        {/* End of HubSpot Embed Code */}
        {/*Start of Zendesk Embed Code */}
        <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=19450bbc-87ed-4529-aa07-a8c708ef478c">
        </script>
        {/* End of Zendesk Embed Code */}
      </Helmet>
      <Grid container spacing={0} className="dashboard-container">
        <Grid item xs={12} sm={6}>
          <div className="content-wrapper">
            <div className="title">
              SoftSpot&trade;:  <span>The first and only mobile app cleared by the FDA for </span>
              Cranial Measurements
            </div>
            <div className="subtitle">
              Smart Digital Tool for Pediatric Head Measurement & Monitoring.
              Recommended for all newborns.
            </div>
            <React.Fragment>
              <ModalVideo channel='custom' autoplay isOpen={isOpen} url="videos/soft_spot_final_rev_nov_2022.mp4" onClose={() => setOpen(false)} />
              <Button
                text="Watch Video"
                additionalClass="started-button"
                onClick={() => setOpen(true)}
              /> &nbsp; &nbsp;
              <Button
                text={"Schedule A Demo \n (For Providers)"}
                additionalClass="demo-button"
                onClick={() => {
                  window.open("https://www.softspot.ai/demo", "_blank");
                }}
              />
            </React.Fragment>
            {/*<div className="provider-schedule-demo">
              <Button
                text="Schedule A Demo (for Providers)"
                additionalClass="header-button"
                onClick={() => {
                  window.open("https://www.softspot.ai/demo", "_blank");
                }}
              />
              </div>*/}
            <div className="app-links-container">
              <a
                href="https://play.google.com/store/apps/details?id=com.pediametrix.softspot"
                target="_blank"
              >
                <img src={IMG_PM_PLAYSTORE} alt="Softspot App For Android" className="mr10" />
              </a>
              <a
                href="https://apps.apple.com/us/app/softsoft/id1543873935"
                target="_blank"
              >
                <img src={IMG_PM_APPSTORE} alt="Softspot App For iOS" className="" />
              </a>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="text-center">
            <img
              className="dashboard-mission-image"
              src={IMG_PM_MOBILE_ILLUSTRATION}
              title=""
              alt="Softspot Mobile App"
            />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={0} className="dashboard-container">
        <Grid item xs={12} sm={6} className="">
          <div className="content-wrapper">
            <div className="title">Our Mission</div>
            <div className="subtitle">
              We bring AI and Computer Vision to pediatric care to lower the
              cost and improve patient outcome. All parents and pediatric
              providers should be given access to accurate and reliable
              measurement tools at the point-of-care. We will make that happen
              given our deep expertise!
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="text-center">
            <img
              className="dashboard-mission-image"
              src={IMG_PM_MISSION_IMAGE}
              title=""
              alt="Softspot Mission Statement"
            />
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={0}
        className="workflow-dashboard-wrapper"
        style={{
          backgroundImage: "url(" + IMG_PM_BG_HOW_IT_WORKS + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
      >
        <div className="title">How it works</div>
        <div className="subtitle">
          SoftSpot is designed to be used for both at-home (for tele measurement
          and monitoring) and in-office.
        </div>
        <Workflow />
      </Grid>
      <Grid container spacing={0} className="testimonial-wrapper">
        <div className="title">Testimonials</div>
        {/*<img className="review-image" src={IMG_REVIEW} alt="Plagiocephaly Self Assessment App" />

          <div className="centered">"SoftSpot is changing the way we diagnose and treat plagiocephaly. Now, we can take serial measurements and monitor the progression of the baby’s cranium and intervene early. The process is time efficient, and it generates revenue for the practice."
            <br /> <h4>-Dr. Susan Sirota, MD, FAAP</h4>
          </div>*/}
        <div>
          <div className="iframe-video-top">
            Dr. Peter Liber, MD, FAAP
            <iframe
              src='https://www.youtube.com/embed/emIL3fzjJw4'
              width="100%"
              height="100%"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
          </div>
          <div className="iframe-video">
            <div>Dr. Susan Sirota, MD, FAAP</div>
            <iframe
              src='https://www.youtube.com/embed/QxDTzkGwSiM'
              width="100%"
              height="100%"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Dr. Susan Sirota, MD, FAAP"
            />
          </div>
          <div className="iframe-video">
            <div>Dr. Andre B. Gvozden, MD</div>
            <iframe
              src='https://www.youtube.com/embed/h1hqkVh-nkg'
              width="100%"
              height="100%"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Dr. Andre B. Gvozden, MD"
            />
          </div>
          <div className="iframe-video">
            <div>Dr. Eric Tyler, MD</div>
            <iframe
              src='https://www.youtube.com/embed/3fV-MqXrRjU'
              width="100%"
              height="100%"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Dr. Eric Tyler, MD"
            />
          </div>
        </div>
        <div className="testimonial-content-wrapper">
          <Slider {...settings}>
            {testimonialData.map((obj) => {
              return (
                <div className="testimonial-content-div">
                  <div className="image-wrapper">
                    <div className="image-container">
                      {obj.type === "parent" && obj.gender === "male" && (
                        <img src={IMG_PM_TESTIMONIAL1} alt="Plagiocephaly Self Assessment App" />
                      )}
                      {obj.type === "parent" && obj.gender === "female" && (
                        <img src={IMG_PM_TESTIMONIAL3} alt="Plagiocephaly Measurements Application" />
                      )}
                      {obj.type === "physician" && (
                        <img src={IMG_PM_TESTIMONIAL2} alt="Plagiocephaly Measurements App" />
                      )}
                    </div>
                    <div>{obj.name}</div>
                  </div>
                  <div className="text-wrapper">{obj.textContent}</div>
                </div>
              );
            })}
          </Slider>
        </div>
      </Grid>
      <Grid
        container
        spacing={0}
        className="team-wrapper"
        ref={props.commonRef ? props.commonRef : null}
      >
        <div className="title">Meet our Team</div>
        <div className="team-members-wrapper">
          {teamMembersData.map((obj) => {
            return (
              <div className="member-container">
                <div className="image-wrapper">
                  <img src={obj.imageLink} alt="Smart Digital Tool For Pediatric Head" />
                </div>
                <div className="member-detail-wrapper">
                  <div className="title">{obj.name}</div>
                  {obj.designation && (
                    <div className="subtitle">{obj.designation || ""}</div>
                  )}
                  <div className="text-center">
                    <a href={obj.profileLink} target="_blank">
                      <img src={IMG_PM_LINKEDIN_ICON} className="profile-img" alt="Softspot Mobile App" />
                    </a>
                  </div>
                </div>
                <div className="text-wrapper">{obj.textContent}</div>
              </div>
            );
          })}
        </div>
      </Grid>
      <Grid container spacing={0} className="sponsors-wrapper">
        <div className="title">Sponsors & Partners</div>
        <div className="sponsor-image-wrapper">
          {sponsorsLinks.map((imageLink, index) => {
            return (
              <div className="image-wrapper">
                <img src={imageLink} alt="Pediatric Head Monitoring" />
              </div>
            );
          })}
        </div>
        <div className="sponsors-gradient-container">
          <Button
            text="SIGN UP NOW"
            additionalClass="header-button"
            onClick={() => {
              window.open("https://login.softspot.ai/sign-up", "_blank");
            }}
          />
        </div>
      </Grid>
      <Grid container spacing={0} className="quick-links-container">
        <Grid xs={12} sm={6}>
          <div className="link-container first-container">
            <div>
              <img src={PM_LOGO} alt="Pediatric Head Measurement" className="footer-logo" />
            </div>
            <div className="link-wrapper">
              <div className="title">Quick Links</div>
              {/* <div>
                <Link to="/contact-us" className="footer-link">
                  About Us
                </Link>
              </div>
              <div>
                <Link to="/contact-us" className="footer-link">
                  Blog
                </Link>
              </div> */}
              <div>
                <Link to="/contact-us" className="footer-link">
                  Contact
                </Link>
              </div>
              <div>
                <Link to="/news" className="footer-link">
                  News
                </Link>
              </div>
              {/* <div>
                <Link to="/contact-us" className="footer-link">
                  FAQ
                </Link>
              </div> */}
            </div>
          </div>
        </Grid>
        <Grid xs={12} sm={6}>
          <div className="link-container">
            <div className="link-wrapper">
              <div className="title">Legal Stuff</div>
              <div>
                <Link to="/disclaimer" className="footer-link">
                  Disclaimer
                </Link>
              </div>
              <div>
                {/*<a
                  href="https://www.pediametrix.com/privacy-policy"
                  target="_blank"
                  className="footer-link"
                >
                  Privacy Policy
                </a>*/}
                <Link to="/privacy-policy" className="footer-link">
                  Privacy Policy
                </Link>
              </div>
              <div>
                <a
                  href="https://pediametrix.accountablehq.com/"
                  target="_blank"
                  className="footer-link"
                >
                  Privacy Center                </a>
              </div>
              <div>
                <Link to="/terms-and-conditions" className="footer-link">
                  Terms &amp; Conditions
                </Link>
              </div>
              <div>
                <a
                  href="PediaMetrixFCOIPolicyRev.01.pdf"
                  target="_blank"
                  className="footer-link"
                >
                  FCOI Policy
                </a>
              </div>
            </div>
            <div>
              <img src={IMG_PM_FOOTER_IMAGE} alt="Plagiocephaly Measurements App" />
              <div>
                <a href="https://www.facebook.com/pediametrix" target="_blank"
                >
                  <img src={IMG_FACEBOOK} className="social-icon" />
                </a>
                <a href="https://twitter.com/pediametrix_inc" target="_blank"
                >
                  <img src={IMG_TWITTER} className="social-icon" />
                </a>
                <a href="https://www.youtube.com/channel/UCi6sRGe7AWZ4LlDR9Ymzwbw/videos" target="_blank"
                >
                  <img src={IMG_YOUTUBE} className="social-icon" />
                </a>
                <a href="https://www.linkedin.com/company/pediametrix/" target="_blank"
                >
                  <img src={IMG_LINKEDIN} className="social-icon" />
                </a>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
