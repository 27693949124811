import React from "react";
import Grid from "@material-ui/core/Grid";
import { IMG_PMLOGO } from "../../PMConstants/PMImageConstants";
import { Helmet } from "react-helmet";
import "./PMBAA.scss";

const BAA = () => {
  window.scrollTo(0, 0);
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://softspot.ai/baa" />
      </Helmet>
      <div className="page-container">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <div className="content-wrapper text-content-wrapper">
              <div className="">
                <p align="center"><h1>Business Associate Agreement</h1></p>
                <p align="left"><h2>Article 1: Introduction</h2></p>

                <p>1.1 Covered Entity and Business Associate enter into this Agreement to comply with the requirements of Health Insurance Portability and Accountability Act of 1996 ("HIPAA"), as amended, including the privacy, security, breach notification and enforcement rules at 45 C.F.R. Part 160 and Part 164, as well as the Health Information Technology for Economic and Clinical Health Act, enacted as part of the American Recovery and Reinvestment Act of 2009 ("HITECH"), as amended, and other applicable federal and state laws (collectively the "HIPAA Rules").

                </p>

                <p>1.2 This Agreement is intended to ensure that Business Associate will establish and implement appropriate safeguards for certain individually identifiable Protected Health Information relating to patients of Covered Entity ("PHI" as that term is defined below) that Business Associate may receive, create, maintain, use or disclose in connection with certain functions, activities and services that Business Associate performs for Covered Entity. The functions, activities and services that Business Associate performs for Covered Entity are defined in one or more agreements between the Parties (the "Underlying Agreements").

                </p>
                <p align="left"><h2>Article 2: Definitions</h2></p>

                <p>
                  2.1 Terms used but not otherwise defined in this Agreement shall have the same meaning as those terms in the HIPAA Rules, which definitions are incorporated in this Agreement by reference.                    </p>

                <p>
                  2.2 For purposes of this Agreement:
                  <ol>
                    <li>
                      "Electronic Protected Health Information" or "ePHI" shall have the meaning given to such term under the Privacy Rule and the Security Rule, including, but not limited to, 45 C.F.R. 160.103, as applied to the information created, received, maintained or transmitted by Business Associate from or on behalf of Covered Entity
                    </li>
                    <li>
                      "Individual" shall have the same meaning given to such term in 45 C.F.R. § 160.103 and shall include a person who qualifies as a personal representative in accordance with 45 C.F.R. § 164.502(g).
                    </li>
                    <li>
                      "Protected Health Information" or "PHI" shall have the meaning given to such term in 45 C.F.R. 160.103, limited to the information created, received, maintained or transmitted by Business Associate from or on behalf of Covered Entity.
                    </li>
                    <li>
                      "Privacy Rule" shall mean the Standards for Privacy of Individually Identifiable Health Information published in 45 C.F.R. Parts 160 and 164, Subparts A and E.
                    </li>
                    <li>
                      "Required by Law" shall have the meaning given to such term in 45 C.F.R. 164.103.
                    </li>
                    <li>
                      "Secretary" shall mean the Secretary of the Department of Health and Human Services or his or her designee.
                    </li>
                    <li>
                      "Security Rule" shall mean the Security Standards at 45 C.F.R. Part 160 and Part 164, Subparts A and C.
                    </li>
                  </ol>
                </p>
                <p align="left"><h2>Article 3: General Obligations of Business Associate</h2></p>
                <p align="left"><h3>3.1 Use and Disclosure.</h3></p>

                <p>
                  Business Associate agrees not to use or disclose PHI, other than as permitted or required by this Agreement or as Required By Law. To the extent Business Associate is carrying out one or more of Covered Entity's obligations under the Privacy Rule pursuant to the terms of the Underlying Agreement or this Agreement, Business Associate shall comply with the requirements of the Privacy Rule that apply to Covered Entity in the performance of such obligation(s).
                </p>
                <p align="left"><h3>3.2 Appropriate Safeguards.</h3>
                </p>

                <p>
                  Business Associate shall use appropriate physical, technical and administrative safeguards, and shall comply with the Security Rule with respect to ePHI, to prevent use or disclosure of PHI other than as provided for by this Agreement or as Required by Law.
                </p>
                <p align="left"><h3>3.3 Mitigation.</h3>
                </p>

                <p>
                  Business Associate agrees to mitigate, to the extent practicable, any harmful effect that is known to Business Associate as a result of a use or disclosure of PHI by Business Associate in violation of this Agreement's requirements or that would otherwise cause a Breach of Unsecured PHI.
                </p>
                <p align="left"><h3>3.4 Breach Reporting.</h3>
                </p>

                <p>
                  Business Associate shall report to Covered Entity any use or disclosure of PHI not permitted under this BAA, Breach of Unsecured PHI or Security Incident, without unreasonable delay, and in any event no more than thirty (30) days following discovery; provided, however, that the Parties acknowledge and agree that this Section constitutes notice by Business Associate to Covered Entity of the ongoing existence and occurrence of attempted but Unsuccessful Security Incidents (as defined below) for which notice to Covered Entity by Business Associate shall be required only upon request. “Unsuccessful Security Incidents” shall include, but not be limited to, pings and other broadcast attacks on Business Associate’s firewall, port scans, unsuccessful log-on attempts, denials of service and any combination of the above, so long as no such incident results in unauthorized access, use or disclosure of PHI. Business Associate’s notification to Covered Entity of a Breach shall include: (i) the identification of each individual whose Unsecured PHI has been, or is reasonably believed by Business Associate to have been, accessed, acquired or disclosed during the Breach; and (ii) any particulars regarding the Breach that Covered Entity would need to include in its notification, as such particulars are identified in 45 C.F.R. § 164.404.
                </p>
                <p align="left"><h3>3.5 Subcontractors.</h3>
                </p>

                <p>
                  In accordance with 45 C.F.R. §§ 164.502(e)(1)(ii) and 164.308(b)(2), if applicable, Business Associate shall enter into a written agreement with any agent or subcontractor that creates, receives, maintains or transmits PHI on behalf of the Business Associate for services provided to Covered Entity, which provides that the agent agrees to the same restrictions, conditions and requirements that apply to the Business Associate with respect to such information
                </p>
                <p align="left"><h3>3.6 Access to PHI.</h3>
                </p>

                <p>
                  Business Associate agrees to provide access to PHI in a Designated Record Set to the Covered Entity. If an Individual makes a request for access pursuant to 45 C.F.R. § 164.524 directly to Business Associate, or inquires about his or her right to access, Business Associate shall forward it to Covered Entity. Any response to such request shall be the responsibility of Covered Entity.
                </p>
                <p align="left"><h3>3.7 Minimum Necessary Requirement.</h3>
                </p>

                <p>
                  Business Associate agrees that when requesting, using or disclosing PHI in accordance with 45 C.F.R. § 502(b)(1) that such request, use or disclosure shall be to the minimum extent necessary, including the use of a "limited data set" as defined in 45 C.F.R. § 164.514(e)(2), to accomplish the intended purpose of such request, use or disclosure, as interpreted under related guidance issued by the Secretary from time to time.
                </p>
                <p align="left"><h3>3.8 Amendment of PHI.</h3>
                </p>

                <p>
                  Business Associate agrees to make PHI contained in a Designated Record Set available to Covered Entity for amendment pursuant to 45 C.F.R. § 164.526. If an Individual makes a request for amendment pursuant to 45 C.F.R. § 164.526 directly to Business Associate, or inquires about his or her right to access, Business Associate shall forward it to Covered Entity. Any response to such request shall be the responsibility of Covered Entity.
                </p>
                <p align="left"><h3>3.9 Accounting of Disclosures.</h3>
                </p>

                <p>
                  Business Associate shall provide to Covered Entity information collected in accordance with Section 3.11 of this Agreement, to permit Covered Entity to respond to a request by an Individual for an accounting of disclosures of PHI in accordance with 45 C.F.R. § 164.528. If any Individual requests an accounting of disclosures of PHI directly from Business Associate, Business Associate shall forward such request to Covered Entity. Any response to such request shall be the responsibility of Covered Entity.
                </p>
                <p align="left"><h3>3.10 Access to Policies and Records.</h3>
                </p>

                <p>
                  Business Associate agrees to make its internal practices, books and records, including policies and procedures regarding PHI, relating to the use and disclosure of PHI and Breach of any Unsecured PHI received from Covered Entity, or create d or received by the Business Associate on behalf of Covered Entity, available to the Secretary for the purpose of Covered Entity or the Secretary determining compliance with the HIPAA Rules.
                </p>
                <p align="left"><h3>3.11 Documentation of Disclosures.</h3>
                </p>

                <p>
                  Business Associate shall document such disclosures of PHI and information related to such disclosures as would be required for Covered Entity to respond to a request by an Individual for an accounting of disclosures of PHI in accordance with 45 C.F.R. § 164.528. Business Associate shall document, at a minimum, the following information (“Disclosure Information”): (i) the date of the disclosure, (ii) the name and, if known, the address of the recipient of the PHI, (iii) a brief description of the PHI disclosed, (iv) the purpose of the disclosure that includes an explanation of the basis for such disclosure, and (v) any additional information required under the HITECH Act and any implementing regulations.
                </p>
                <p align="left"><h2>Article 4: Permitted Uses and Disclosures by Business Associate</h2></p>
                <p align="left"><h3>4.1 General Uses and Disclosures.</h3></p>

                <p>
                  Business Associate agrees to receive, create, use or disclose PHI only as permitted by this Agreement, the HIPAA Rules, and only in connection with providing services to Covered Entity; provided that the use or disclosure would not violate the Privacy Rule if done by Covered Entity, except as set forth in this Article 4.
                </p>
                <p align="left"><h3>4.2 Business Associate may use or disclose PHI as Required By Law.</h3></p>
                <p align="left"><h3>4.3 Except as otherwise provided in this Agreement, Business Associate may:</h3>
                  <ol>
                    <li>
                      Use PHI for the proper management and administration of Business Associate, or to carry out its legal responsibilities.
                    </li>
                    <li>
                      Disclose PHI for the proper management and administration of Business Associate or to carry out legal responsibilities of Business Associate, provided that the dis closures are Required by Law, or Business Associate obtains prior written reasonable assurances from the person to whom the information is disclosed that the information will remain confidential and used or further disclosed only as Required b y Law or for the purposes for which it was disclosed to the person, and the person notifies the Business Associate of any instances of which it is aware in which the confidentiality of the information has been breached, in accordance with the breach notification requirements of this Agreement.
                    </li>
                    <li>
                      Use PHI to provide Data Aggregation Services to Covered Entity as permitted under the HIPAA Rules.
                    </li>
                  </ol>
                </p>

                <p align="left"><h2>Article 5: Obligations of Covered Entity</h2></p>
                <p align="left"><h3>5.1 Covered Entity shall:</h3></p>
                <ol>
                  <li>
                    Notify Business Associate of any limitation(s) in its Notice of Privacy Practices in accordance with 45 C.F.R. 164.520, to the extent that such limitation may affect Business Associate's use or disclosure of PHI.
                  </li>
                  <li>
                    Notify Business Associate of any restriction to the use or disclosure of PHI that Covered Entity has agreed to in accordance with 45 C.F.R. § 164.522, to the extent that such changes may affect Business Associate's use or disclosure of PHI.
                  </li>
                  <li>
                    Notify Business Associate of any changes in or revocation of permission by an individual to use or disclose his or her PHI, to the extent that such change or revocation may affect Business Associate's permitted or required uses and disclosures of PHI.
                  </li>
                </ol>
                <p align="left"><h2>Article 6: Term and Termination</h2></p>
                <p align="left"><h3>6.1 Term</h3></p>
                <p align="left">This Agreement shall be in effect as of the Effective Date and shall terminate on the earlier of the date that:</p>
                <ol>
                  <li>
                    Either party terminates for cause as authorized under Section 6.2.
                  </li>
                  <li>
                    All PHI received from Covered Entity, or created or received by Business Associate on behalf of Covered Entity, is destroyed or returned to Covered Entity. If it is determined, to be infeasible to return or destroy PHI, protections are extended to such information in accordance with Section 6.3.
                  </li>
                </ol>
                <p align="left"><h3>6.2 Termination for Cause.</h3></p>
                <p align="left">
                  Upon Covered Entity’s knowledge of material breach by Business Asssociate, Covered Entity shall provide an opportunity for Business Associate to cure the breach or end the violation. If Business Associate does not cure the breach or end the violation within the timeframe specified by Covered Entity, or if a material term of this Agreement has been breached and a cure is not possible, Covered Entity may terminate this Agreement and the Underlying Agreement(s), if any, upon written notice to Business Associate.
                </p>
                <p align="left"><h3>6.3 Obligations of Business Associate Upon Termination</h3></p>
                <p align="left">Upon termination of this Agreement for any reason, Business Associate, with respect to PHI received from Covered Entity, or created, maintained, or received by Business Associate on behalf of Covered Entity, shall:
                </p>
                <ol>
                  <li>
                    Retain only that PHI that is necessary for Business Associate to continue its proper management and administration or to carry out its legal responsibilities;
                  </li>
                  <li>
                    Return to Covered Entity or, if agreed to by Covered Entity in writing, destroy the remaining PHI that the Business Associate still maintains in any form;
                  </li>
                  <li>
                    Continue to use appropriate safeguards and comply with Subpart C of 45 C.F.R. Part 164 with respect to ePHI to prevent use or disclosure of the PHI, other than as provided for in this Section 6, for as long as Business Associate retains the PHI;
                  </li>
                  <li>
                    Limit further uses and disclosures of such PHI to those purposes that make the return or destruction infeasible, for so long as Business Associate maintains such PHI;
                  </li>
                  <li>
                    Return to Covered Entity or destroy the PHI retained by Business Associate when it is no longer needed by Business Associate for its proper management and administration or to carry out its legal responsibilities.
                  </li>
                </ol>
                <p align="left"><h2>Article 7: Miscellaneous</h2></p>
                <p align="left"><h3>7.1 Amendment</h3></p>
                <p align="left">
                  The Parties agree to take such action as is necessary to amend this Agreement to comply with the requirements of the HIPAA Rules and any other applicable law.
                </p>
                <p align="left"><h3>7.2 Survival.</h3></p>
                <p align="left">
                  The respective rights and obligations of Business Associate under Article 6 of this Agreement shall survive the termination of this Agreement.
                </p>
                <p align="left"><h3>7.3 Regulatory References.</h3></p>
                <p align="left">
                  A reference in this Agreement to a section of the HIPAA Rules means the sectionas in effect or amended.
                </p>
                <p align="left"><h3>7.4 Interpretation</h3></p>
                <p align="left">
                  This Agreement shall be interpreted in the following manner:
                </p>
                <ol>
                  <li>
                    Any ambiguity shall be resolved in favor of a meaning that permits Covered Entity to comply with the HIPAA Rules
                  </li>
                  <li>
                    Any inconsistency between the Agreement's provisions and the HIPAA Rules, including all amendments, as interpreted by the Department of Health and Human Services, court or another regulatory agency with authority over the Parties, shall be interpreted according to the interpretation of the Department of Health and Human Services, the court or the regulatory agency.
                  </li>
                  <li>
                    Any provision of this Agreement that differs from those mandated by the HIPAA Rules, but is nonetheless permitted by the HIPAA Rules, shall be adhered to as stated in this Agreement.
                  </li>
                </ol>
                <p align="left"><h3>7.5 Entire Agreement, Severability.</h3></p>
                <p align="left">
                  This Agreement constitutes the entire agreement between the Parties related to the subject matter of this Agreement, except to the extent that the Underlying Agreement(s), if any, impose more stringent requirements related to the use and protection of PHI upon Business Associate. This Agreement supersedes all prior negotiations, discussions, representations or proposals, whether oral or written. This Agreement may not be modified unless done so in writing and signed by a duly authorized representative of both Parties. If any provision of this Agreement, or part thereof, is found to be invalid, the remaining provisions shall remain in effect.
                </p>
                <p align="left"><h3>7.6 Assignment.</h3></p>
                <p align="left">
                  This Agreement will be binding on the successors and assigns of Covered Entity and Business Associate. However, this Agreement may not be assigned by Business Associate, in whole or in part, without the written consent of Covered Entity. Any attempted assignment in violation of this provision shall be null and void.
                </p>
                <p align="left"><h3>7.7 Multiple Counterparts.</h3></p>
                <p align="left">
                  This Agreement may be executed in two or more counterparts, each of which shall be deemed an original.
                </p>
                <p align="left"><h3>7.8 Governing Law.</h3></p>
                <p align="left">
                  Except to the extent preempted by federal law, this Agreement shall be governed by and construed in accordance with the laws of the state in which the Business Associate’s principal place of business is located.
                </p>
              </div>

            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default BAA;
