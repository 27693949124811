import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { Player, BigPlayButton } from "video-react";
import { Helmet } from "react-helmet";

import Button from "../../PMComponents/PMButton/PMButton";
import {
  PM_LOGO,
  DEMO_PAGE_IMAGE,
  SHOPPING_CART_IMAGE
} from "../../PMConstants/PMImageConstants";
import "./PMVideo.scss";
import { Link } from "react-router-dom";

const Video = (props) => {

  return (
    <>
      <Helmet>
        {/*Start of HubSpot Embed Code */}
        <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/3289469.js"></script>
        {/* End of HubSpot Embed Code */}
        {/*Start of Zendesk Embed Code */}
        <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=19450bbc-87ed-4529-aa07-a8c708ef478c">
        </script>
        {/* End of Zendesk Embed Code */}
      </Helmet>
      <Grid container spacing={0} className="demo-dashboard-container">
        <Grid item xs={12}>
          <div className="header-wrapper">
            <div>
              <a
                href="https://softspot.ai"
                target="_blank"
              >
                <img className="" src={PM_LOGO} title="" alt="Plagiocephaly Self Assessment App" />
              </a>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="demo-content-wrapper">
            <div className="player-container">
              <div className="player-wrapper">
                <Player className="react-player" autoPlay={true} muted={true}>
                  <source src='videos/soft_spot_final_rev.mp4' />
                  <BigPlayButton position="center" />
                </Player>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Video;