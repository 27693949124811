import React from "react";
import PropTypes from "prop-types";
import MatButton from "@material-ui/core/Button";

const Button = ({
  additionalClass = "",
  onClick = () => {},
  type = "button",
  name = "",
  disabled = false,
  text = "",
}) => {
  return (
    <MatButton
      variant="contained"
      className={`pm-button ${additionalClass}`}
      onClick={onClick}
      type={type}
      name={name}
      disabled={disabled}
    >
      {text}
    </MatButton>
  );
};

export default Button;

Button.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  additionalClass: PropTypes.string,
};
