import React from "react";
import Grid from "@material-ui/core/Grid";
import { IMG_PMLOGO } from "../../PMConstants/PMImageConstants";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  window.scrollTo(0, 0);
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://softspot.ai/privacy-policy" />
      </Helmet>
      <div className="page-container">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <div className="content-wrapper text-content-wrapper">
              <div className="">
                <p align="center"><h1>Privacy Policy</h1></p>
                <p><i>Note: Our chat system is designated for technical support only. While we prioritize data security, please refrain from sharing protected health information (PHI) over chat, as the chat system is not currently part of our HIPAA compliant platform.</i></p>
                <p>This Privacy Policy sets forth the information gathering, use, and dissemination practices of PediaMetrix Inc., with respect to www.PediaMetrix.com and its affiliated web pages, sites, and mobile applications including SoftSpot&trade;. This privacy policy has been compiled to better serve those who are concerned with how their Personal Information is being used online.</p>

                <p>&ldquo;Personal Information&rdquo; is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website.</p>

                <p>&ldquo;Sensitive Personal Information&rdquo; includes data about racial or ethnic origin, political opinions, religious or philosophical beliefs, trade-union memberships, genetic and biometric data when used for identification purposes, and data about health, sex life, and sexual orientation.</p>

                <p><b>Scope</b></p>

                <p>This Privacy Policy applies to the information that we obtain through your use of PediaMetrix products and services, including our website (https://www.PediaMetrix.com), our web and mobile-based tools, social media, communications (collectively, our &ldquo;Services&rdquo;).</p>

                <p>Types of Personal Information that We Collect</p>
                <ul>

                  <li>Names</li>

                  <li>Email addresses</li>

                  <li>Telephone numbers</li>

                  <li>Date of births</li>

                  <li>User IDs and passwords</li>

                  <li>Identifiers of devices used to access our Services</li>

                  <li>Infant&rsquo;s head images (even though not identifiable in most cases, may include identifiable body liarts)</li>

                </ul>
                <p><b>Information that We Collect from and About You</b></p>

                <p><i>Information that You Provide to Us Voluntarily</i></p>

                <p>Account and Profile Information: We collect information about you and your company when you register for an account, create or modify your profile, and make purchases through our Services. Information we collect includes your name, username, address, email address, and phone number. You may provide this information directly through our Services or in some cases another user (such as an account administrator) creating an account on your behalf may provide it. If you provide information (including personal information) about someone else, you confirm that you have the authority to act for them and to consent to the collection and use of their personal information as described in this Privacy Policy.</p>

                <p>Content: We collect and store content that you create, input, submit, post, upload, transmit, or store while using our Services. Such content may include any personal or other sensitive information submitted using our Services, such as HIPAA protected health information, and other information such as source code or regulatory compliance materials.</p>

                <p><b>Other submissions:</b> We collect other data that you may submit to our Services or to us directly, such as when you request customer support or communicate with us via email or social media sites.</p>

                <p></p>

                <p><i>Information that We Collect Automatically When You Use Our Services</i></p>

                <p>Web Logs and Analytics Information: We record certain information and store it in log files when you interact with our Services. This information may include Internet protocol (IP) or other device addresses or ID numbers as well as browser type, Internet service provider, URLs of referring/exit pages, operating system, date/time stamp, information that you search for, your locale and language preferences, your mobile carrier, and system configuration information. We and our analytics providers, also collect and store analytics information when you use our Services to help us improve our Services.</p>

                <p>Cookies and Other Tracking Technologies: We use various technologies to collect information, including cookies that we save to your computer or mobile device. Cookies are small data files stored on your hard drive or in device memory. We use cookies to improve and customize our Services and your experience; to allow you to access and use the Services without re-entering your username or password; and to count visits and understand which areas and features of the Services are most popular. We may also associate the information we store in cookies with personal information you submit while on our Services. You can instruct your browser, by changing its options, to stop accepting cookies or to prompt you before accepting a cookie from websites you visit. If you do not accept cookies, which is strictly necessary for the provision of our services, you may not be able to use all aspects of our Services.</p>

                <p><i>Information that We Collect from Other Sources</i></p>

                <p>Information provided by other individuals: While using our Services, individuals may provide information about another individual, or an authorized user (such as an account administrator or a doctor&rsquo;s office administrator) creating an account on your behalf may provide information about You. When one individual provides us with information (including personal information) about another individual, we assume that the individual has permission and authority to do so and to consent on behalf of that individual to the collection and use of personal information as described in this Privacy Policy. Please contact us immediately at contact@PediaMetrix.com if you become aware of an individual providing us with personal information about another individual without being authorized to do so, and we will act consistently with this Privacy Policy.</p>

                <p><i>Information that We Receive About You From Our Customers</i></p>

                <p>Our customers and their designated users use our Services to develop, establish, implement, and maintain secure application and database deployment environments for processing sensitive data, including personal information and sensitive personal information. While using our Services, our customers may create, input, submit, post, upload, transmit, or store personal information that they have collected from individuals. During the course of our business relationship, we may need to access a customer&rsquo;s account and the information it contains to provide support for our Services.</p>

                <p>Our customers and prospective customers are responsible for complying with all applicable federal, state, local, and international laws and regulations regarding notice, disclosure, consent, and transfer of personal information, prior to providing that personal information to PediaMetrix.</p>


                <p><b>Why We Collect Information from and About You</b></p>

                <p>We will not use your personal information for anything other than the following lawful purposes:</p>

                <p></p>
                <ul>
                  <li>To establish and maintain contractual relationships with our customers:</li>

                  <li>To establish relationships with new customers</li>

                  <li>To fulfill our obligations to current customers</li>

                  <li>To contact customers regarding account-related issues and business communications relating to the Services, including technical notices, updates, security alerts, and administrative messages</li>

                  <li>To enable individuals to access and use our Services</li>

                  <li>To finalize and administer your request and service</li>
                </ul>
                <p></p>

                <p>To comply with our legal obligations :</p>
                <ul>

                  <li>To comply with legal obligations, including but not limited to complying with tax and financial reporting requirements</li>

                  <li>To demonstrate compliance with applicable privacy and data security laws and regulations, such as HIPAA and GDPR</li>

                  <li>To comply with incident monitoring, reporting, assessment, and notification requirements</li>

                  <li>To comply with other applicable criminal and civil law and regulatory requirements under federal, state, and international law</li>
                </ul>

                <p></p>

                <p>To provide services and information that you request and consent to receive:</p>
                <ul>

                  <li>To provide customer service and support</li>

                  <li>To communicate with you, including responding to your comments, questions, and requests regarding our Services</li>

                  <li>To process and complete transactions, and send you related information, including purchase confirmations and invoices</li>

                  <li>To provide direct marketing, email, and other distributed information distribution</li>
                </ul>

                <p></p>

                <p>To full fill our other legitimate interests to the extent that they are not overridden by individual interests, fundamental rights, or freedoms:</p>
                <ul>
                  <li>To administer, operate, maintain, and secure our website and Services</li>

                  <li>To monitor and analyze trends, usage, and activities in connection with our Services</li>

                  <li>To investigate and prevent fraudulent transactions, unauthorized access to our Services, and other illegal activities</li>

                  <li>To verify compliance with our internal policies and procedures</li>

                  <li>For accounting, recordkeeping, backup, and administrative purposes</li>

                  <li>To customize and improve the content of our communications, websites, and social media accounts</li>

                  <li>To educate and train our workforce in data protection and customer support</li>

                  <li>To provide, operate, maintain, improve, personalize, and promote our Services</li>

                  <li>To develop new products, services, features, and functionality</li>

                  <li>To market our products and services (first-party marketing only; we do not provide personal information for use in marketing any non-PediaMetrix, third-party goods or services)</li>
                </ul>
                <p></p>

                <p>When possible, we will use anonymized data for these purposes, but if we do not, or if we combine it with Personal Information we will treat it in accordance with this Privacy Policy.</p>

                <p></p>

                <p><b>How do we protect your information?</b></p>
                <ul>

                  <li>We do not use Malware Scanning.</li>

                  <li>Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential. In addition, all sensitive/credit information you supply is encrypted via Secure Socket Layer (SSL) technology.</li>

                  <li>We implement a variety of security measures when a user enters, submits, or accesses their information to maintain the safety of your personal information.</li>

                  <li>All transactions are processed through a gateway provider and are not stored or processed on our servers.</li>

                  <li>Personal data will be erased or anonymized that they fulfil their purpose where there is no retention obligation. We will remove the identifiable information of yours where it is no longer necessary unless there is a regulatory obligation, or the primary interest of our company or any other third party, or administrative order.</li>
                </ul>
                <p></p>

                <p><b>How we can share your data</b></p>

                <p>There will be times when we need to share your personal data with third parties. We will only disclose your personal data to:</p>
                <ul>
                  <li>third party service providers and partners who assist and enable us to use the personal data to, for example, support delivery of or provide functionality on the website or services, or to market or promote our goods and services to you</li>

                  <li>regulators, law enforcement bodies, government agencies, courts or other third parties where we think it&rsquo;s necessary to comply with applicable laws or regulations, or to exercise, establish or defend our legal rights. Where possible and appropriate, we will notify you of this type of disclosure</li>

                  <li>an actual or potential buyer (and its agents and advisers) in connection with an actual or proposed purchase, merger or acquisition of any part of our business</li>

                  <li>other people where we have your consent.</li>
                </ul>

                <p></p>

                <p><b>Your rights</b></p>

                <p>It&rsquo;s your personal data and you have certain rights relating to it. When it comes to marketing communications, you can ask us not to send you these at any time &ndash; just follow the unsubscribe instructions contained in the marketing communication, or send your request to contact@PediaMetrix.com.</p>

                <p></p>

                <p>You also have rights to:</p>
                <ul>
                  <li>know what personal data we hold about you, and to make sure it&rsquo;s correct and up to date</li>

                  <li>request a copy of your personal data, or ask us to restrict processing your personal data or delete it</li>

                  <li>object to our continued processing of your personal data</li>
                </ul>
                <p></p>

                <p>You can exercise these rights at any time by sending an email to contact@PediaMetrix.com.</p>

                <p>If you&rsquo;re not happy with how we are processing your personal data, please let us know by sending an email to contact@PediaMetrix.com. We will review and investigate your complaint, and try to get back to you within a reasonable time frame. You can also complain to your local data protection authority. They will be able to advise you how to submit a complaint.</p>

                <p></p>

                <p><b>California Online Privacy Protection Act</b></p>

                <p>CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy policy. The law&rsquo;s reach stretches well beyond California to require any person or company in the United States (and conceivably the world) that operates websites collecting Personally Identifiable Information from California consumers to post a conspicuous privacy policy on its website stating exactly the information being collected and those individuals or companies with whom it is being shared.</p>

                <p>According to CalOPPA, we agree to the following:</p>
                <ul>
                  <li>Users can visit our site anonymously.</li>

                  <li>Once this privacy policy is created, we will add a link to it on our home page or as a minimum, on the first significant page after entering our website.</li>

                  <li>Our Privacy Policy link includes the word &lsquo;Privacy&rsquo; and can easily be found on the page specified above.</li>
                </ul>
                <p></p>

                <p><i>COPPA (Children Online Privacy Protection Act)</i></p>

                <p>When it comes to the collection of personal information from children under the age of 13 years old, the Children&rsquo;s Online Privacy Protection Act (COPPA) puts parents in control. The Federal Trade Commission, United States&rsquo; consumer protection agency, enforces the COPPA Rule, which spells out what operators of websites and online services must do to protect children&rsquo;s privacy and safety online.</p>

                <p>Even though we may collect personal data that belong to children of any age, we do not specifically market to children under the age of 13 years old.</p>

                <p></p>

                <p><b>Fair Information Practices</b></p>

                <p>The Fair Information Practices Principles form the backbone of privacy law in the United States and the concepts they include have played a significant role in the development of data protection laws around the globe. Understanding the Fair Information Practice Principles and how they should be implemented is critical to comply with the various privacy laws that protect personal information.</p>

                <p>In order to be in line with Fair Information Practices we will take the following responsive action, should a data breach occur:</p>
                <ul>
                  <li>We will notify you via email</li>

                  <li>Within 7 business days</li>
                </ul>
                <p></p>

                <p>We also agree to the Individual Redress Principle which requires that individuals have the right to legally pursue enforceable rights against data collectors and processors who fail to adhere to the law. This principle requires not only that individuals have enforceable rights against data users, but also that individuals have recourse to courts or government agencies to investigate and/or prosecute non-compliance by data processors.</p>

                <p></p>

                <p><b>CAN SPAM Act</b></p>

                <p>The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for commercial messages, gives recipients the right to have emails stopped from being sent to them, and spells out tough penalties for violations.</p>

                <p>To be in accordance with CANSPAM, we agree to the following:</p>
                <ul>
                  <li>Not use false or misleading subjects or email addresses.</li>

                  <li>Identify the message as an advertisement in some reasonable way.</li>

                  <li>Include the physical address of our business or site headquarters.</li>

                  <li>Monitor third-party email marketing services for compliance, if one is used.</li>

                  <li>Honor opt-out/unsubscribe requests quickly.</li>

                  <li>Allow users to unsubscribe by using the link at the bottom of each email.</li>
                </ul>
                <p></p>

                <p><b>Notification of Changes</b></p>

                <p>We may change this Privacy Policy from time to time. If we make any changes, we will notify you by revising the date on this Privacy Policy and, in some cases, where appropriate we may provide you with additional notice.</p>

                <p>Your continued use of our Services after the revised Policy has become effective indicates that you have read, understood, and agreed to the current version of this Policy.</p>


                <p>The Company is committed to complying with the Privacy Rule.</p>


                <p>The Company recognizes the need to protect the privacy of PHI in order to facilitate the effective delivery of health care. These Privacy Policies and Procedures are designed and intended to ensure the Company&rsquo;s compliance with the Privacy Rule. The Company adopts these Policies and Procedures to protect the PHI that it creates and maintains from unauthorized use, disclosure, or access, and to maintain the confidentiality and integrity of that PHI. These Policies and Procedures also ensure that individuals have rights related to their PHI. Through the Company&rsquo;s Notice of Privacy Practices (&quot;Privacy Notice&quot;) individuals are informed of the Company&rsquo;s legal duties and these Policies and Procedures, as well as their individual rights with respect to their PHI.</p>


                <p>By agreeing, you acknowledge receipt of the Notice of Privacy Practices (&ldquo;NPP&rdquo;) of PediaMetrix Inc. Our NPP provides information about how we may use and disclose your protected health information. We encourage you to read it in full.</p>


                <p>Our NPP is subject to change. If we change our NPP, you may obtain a copy of the revised NPP by accessing our website at <span><a href="http://www.PediaMetrix.com" target="_blank">www.pediametrix.com</a></span> or contacting us at <a href="mailto:contact@pediametrix.com" target="_self">contact@pediametrix.com.</a><br />

                  Rev. A<br />
                  Published: 01/01/2021</p>

                <p><b>Contacting Us</b></p>

                <p>If there are any questions regarding this privacy policy, you may contact us using the information below.<br />
                  <span><a href="http://www.PediaMetrix.com" target="_blank">www.PediaMetrix.com</a> </span> <br />
                  155 Gibbs St, Suite 537 <br />
                  Rockville, MD 20850 <br />
                  United States <br />
                  <a href="mailto:contact@pediametrix.com" target="_self">contact@PediaMetrix.com</a>
                  <br />
                  +1 (240) 670-0171</p>
              </div>
              <div><a
                href="https://pediametrix.accountablehq.com/"
                target="_blank"
                className="footer-link"
              >
                Privacy Center                </a><br />
                Using our Privacy Center, you can request to access or delete your data.</div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default PrivacyPolicy;
