import fieldName from "./PMFieldName";

const messages = (messageFor) => {
  let messageObj = {
    required: "",
    invalid: "",
  };
  switch (messageFor) {
    case fieldName.FIRSTNAME:
      messageObj = {
        required: "First name is required",
      };
      break;
    case fieldName.LASTNAME:
      messageObj = {
        required: "Last name is required",
      };
      break;
    case fieldName.EMAIL:
      messageObj = {
        required: "Email is required",
        invalid: "Email is invalid",
      };
      break;
    case fieldName.PHYSICIAN_ID:
    case fieldName.DOCTOR_NAME:
      messageObj = {
        required: "Physician name is required",
      };
      break;
    case fieldName.CHILD_NAME:
      messageObj = {
        required: "Child name is required",
      };
      break;
    case fieldName.CHILD_DOB:
      messageObj = {
        required: "Child DOB is required",
        invalid: "Please enter proper date in format (MM/DD/YYYY)",
      };
      break;
    case fieldName.OLD_PASSWORD:
    case fieldName.PASSWORD:
      messageObj = {
        required: "Password is required",
        invalid:
          "Password must contain minimum 8 characters with atleast one upper case, one lowercase, one number and one special character",
      };
      break;
    case fieldName.CONFIRM_PASSWORD:
      messageObj = {
        required: "Confirm Password is required",
        // invalid:
        // "Password must contain minimum 8 characters with atleast one upper case, one lowercase, one number and one special character",
        match: "Passwords do not match",
      };
      break;
    case fieldName.MOBILE_NUMBER:
      messageObj = {
        required: "Mobile number is required",
        invalid: "Mobile number is invalid",
      };
      break;
    case fieldName.RELATIONSHIP:
      messageObj = {
        required: "Relationship is required",
      };
      break;
    case fieldName.PATIENT_NAME:
      messageObj = {
        required: "Patient name is required",
      };
      break;
    case fieldName.PATIENT_GENDER:
      messageObj = {
        required: "Gender is required",
      };
      break;
    default:
      break;
  }
  return messageObj;
};

export default messages;
