import React, { useState, useEffect } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import "./PMWorkflow.scss";
import {
  IMG_PM_WORKFLOW_CIRCULAR,
  IMG_PM_WORKFLOW_SQUARE,
} from "../../PMConstants/PMImageConstants";

const Workflow = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className="content-wrapper">
      {/* <div className="workflow-title">
        It will help you understand the journey better
      </div> */}
      <div className="">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="worklow tabs"
          className="workflow_tabs"
        >
          {/*<Tab label="Workflow" className="first_tab" />*/}
          {/*<Tab label="In Office" />*/}
        </Tabs>
        <div role="tabpanel" className="workflow_tabpanel workflow-wrapper">
          {value === 0 && (
            <div className="workflow-square workflow-circle">
              <div className="workflow-img-container">
                <div className="text-row">
                  <div className="text-container">
                    <div className="mobile-title">1.</div>
                    <div className="title">1. Sign in to your account</div>
                    <div className="sub-title">
                      To activate your account for the first time, <div>you must signup and purchase the kit    </div>                </div>
                  </div>
                </div>
                <div className="workflow-container">
                  <div className="text-container mr10">
                    <div className="mobile-title">5.</div>
                    <div className="title_five">5. Talk to an expert</div>
                    <div className="sub-title-five">
                      Softspot report should be
                      <div>interpreted by a qualified user</div>
                    </div>
                  </div>
                  <div className="workflow-image-overlap">
                    <img alt="" src={IMG_PM_WORKFLOW_CIRCULAR} />
                    {/*<div className="text-content">Repeat monthly</div>*/}
                  </div>
                  <div className="text-container ml10">
                    <div className="mobile-title">2.</div>
                    <div className="title_two">2. Upload Information</div>
                    <div className="sub-title-two">
                      Upload photos/videos
                    </div>
                  </div>
                  {/*<div className="workflow-image-text">Takes upto 3 days</div>*/}
                </div>
                <div className="text-row top-padding-0">
                  <div className="text-container">
                    <div className="mobile-title">4.</div>
                    <div className="title_four">4. Notified for Result</div>
                    <div className="sub-title-four">
                      You will be notified when results are available
                    </div>
                  </div>
                  <div className="text-container">
                    <div className="mobile-title">3.</div>
                    <div className="title_three">3. Generate Report</div>
                    <div className="sub-title-three">
                      PediaMetrix generates the report
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/*} {value === 1 && (
            <div className="workflow-square">
              <div className="workflow-img-container">
                <div className="text-row">
                  <div className="text-container">
                    <div className="title">1. Physician Upload Information</div>
                    <div className="sub-title">
                      Physician upload photos/videos
                    </div>
                  </div>
                  <div className="text-container">
                    <div className="title">2. Generate Report</div>
                    <div className="sub-title">
                      Pediametrix generates the report
                    </div>
                  </div>
                </div>
                <div className="workflow-image-overlap">
                  <img alt="Plagiocephaly Self Assessment App" src={IMG_PM_WORKFLOW_SQUARE} />
                </div>
                <div className="text-row">
                  <div className="text-container">
                    <div className="title">4. Visit</div>
                    <div className="sub-title">
                      You have a tele health or in-office visit
                      <div>(ICD-10 Q67.3)</div>
                    </div>
                  </div>
                  <div className="text-container">
                    <div className="title">3. Notified for Result</div>
                    <div className="sub-title">
                      You will be notified when results are available
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}*/}
        </div>
      </div>
    </div>
  );
};

export default Workflow;
