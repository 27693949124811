const actions = {
  GET_AUTH_TOKEN: "getAuthToken",
  AUTH_ERROR: "authError",
  USER_CREATED: "user_created",
  USER_CREATE_ERROR: "user_create_error",
  REDIRECT_USER: "redirect_user",
  SHOW_LOADER: "show_loader",
  HIDE_LOADER: "hide_loader",
  SIGNUP_USER: "signup",
  SIGNUP_ERROR: "signup_error",
  REGISTER_BABY: "register_baby",
  REGISTER_BABY_ERROR: "register_baby_error",
  DISPLAY_NOTIFICATION: "display_notification",
  DISPLAY_NOTIFICATION_LOGIN: "display_notification_login",
  DISPLAY_USER_CHAT: "display_user_chat",
  INCREMENT_MESSAGE_COUNT: "increment_message_count",
  UPDATE_PROFILE_PIC_SUCCESS: "update_profile_pic_success",
  UPDATE_PROFILE_PIC_ERROR: "update_profile_pic_error",
  UPDATE_PROFILE_PIC_RESET: "update_profile_pic_reset",
  GET_PROFILE_PIC_ERROR: "get_profile_pic_error",
  GET_PROFILE_PIC_SUCCESS: "get_profile_pic_success",
  RESET_ACTIVE_REPORT: "upload_report_reset",
  EMAIL_CHAT_MESSAGES_SUCCESS: "email_chat_messages_success",
  EMAIL_CHAT_MESSAGES_ERROR: "email_chat_messages_error",
  EMAIL_CHAT_MESSAGES_RESET: "email_chat_messages_reset",
};

export default actions;
