import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "../PMButton/PMButton";
import "./PMHeader.scss";
import { PM_LOGO } from "../../PMConstants/PMImageConstants";
import { Menu } from "@material-ui/core";
import config from "../../config";
import Dropdown from "../PMDropdown/PMDropdown";

const Header = (props) => {
  const menuRef = useRef(null);

  const [showMenu, setShowMenu] = useState(false);
  const [activeLink, setActiveLink] = useState(
    props && props.activePath ? props.activePath : ""
  );
  const [menuEl, setMenuEl] = React.useState(null);
  const executeScroll = () => {
    if (props.commonRef && props.commonRef.current) {
      props.commonRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  const handleCloseMenu = () => {
    setShowMenu(false);
    setMenuEl(null);
  };

  return (
    <Grid item xs={12}>
      <div className="header-wrapper">
        <div>
          <Link to="/">
            <img className="" src={PM_LOGO} title="" alt="Plagiocephaly Self Assessment App" />
          </Link>
        </div>
        <div className="display-flex header-nav-panel">
          <div className="header-nav">
            <Link
              to="/"
              className={`${activeLink === "" ? "active" : ""}`}
              onClick={() => setActiveLink("")}
            >
              Home
            </Link>
            <a href="https://login.softspot.ai" target="_blank">
              SoftSpot&trade;
            </a>
            <a href="https://docs.google.com/document/d/1_mKfOpoMuit-5Hs60d7oUIt-WgONiRPPNNzBkEkhkR8/edit" target="_blank">Publications</a>
            <div class="dropdown">
              <Link
                to="/"
                className={`${activeLink === "parent-help-center" || activeLink === "physician-help-center" ? "active" : ""}`}
                onClick={() => setActiveLink("")}
              >Help Center</Link>
              <div class="dropdown-content">
                <Link
                  to="/parent-help-center"
                  className={`${activeLink === "parent-help-center" ? "active" : ""}`}
                  onClick={() => setActiveLink("parent-help-center")}
                >
                  Parents
                </Link>
                <Link
                  to="/physician-help-center"
                  className={`${activeLink === "physician-help-center" ? "active" : ""}`}
                  onClick={() => setActiveLink("physician-help-center")}
                >
                  Physicians
                </Link>
              </div>
            </div>
            <div class="dropdown link-wrapper">
              <Link
                to="/"
                className={`${activeLink === "shop-mckesson" || activeLink === "shop-henry-schein" ? "active" : ""}`}
                onClick={() => setActiveLink("")}
              >Shop</Link>
              <div class="dropdown-content">
                <a href="https://mms.mckesson.com/product/1223079/Florida-Medical-Sales-PED-SOFTSPOT10" target="_blank">McKesson</a>
                <a href="https://www.henryschein.com/us-en/medical/p/equipment/diagnostic-tools/pediatric-kit-softspot/1434234" target="_blank">                  Henry Schein
                </a>
              </div>
            </div>
            <div class="dropdown">
              <Link
                to="/"
                className={`${activeLink === "news" || activeLink === "contact-us" ? "active" : ""}`}
                onClick={() => setActiveLink("")}
              >About Us</Link>
              <div class="dropdown-content">
                <Link
                  to="/"
                  onClick={() => {
                    setActiveLink("");
                    setTimeout(() => {
                      executeScroll();
                    }, 400);
                  }}
                >
                  Team
                </Link>
                <Link
                  to="/news"
                  className={`${activeLink === "news" ? "active" : ""}`}
                  onClick={() => setActiveLink("news")}
                >
                  News
                </Link>
                <Link
                  to="/quality-policy"
                  className={`${activeLink === "quality-policy" ? "active" : ""}`}
                  onClick={() => setActiveLink("quality-policy")}
                >
                  Quality Policy
                </Link>
                <Link
                  to="/contact-us"
                  className={`${activeLink === "contact-us" ? "active" : ""}`}
                  onClick={() => setActiveLink("contact-us")}
                >
                  Contact Us
                </Link>
              </div>
            </div>
            {/*<Link
              to="/contact-us"
              className={`${activeLink === "contact-us" ? "active" : ""}`}
              onClick={() => setActiveLink("contact-us")}
            >
              Contact Us
            </Link>*/}

          </div>
          <Button
            text="Sign In"
            additionalClass="header-secondary-button"
            onClick={() => {
              window.open("https://login.softspot.ai/", "_blank");
            }}
          />
          <Button
            text="Sign Up"
            additionalClass="header-button"
            onClick={() => {
              window.open("https://login.softspot.ai/sign-up", "_blank");
            }}
          />
        </div>
        <div className="header-menu-container">
          <IconButton
            aria-label="close"
            onClick={(event) => {
              setShowMenu(!showMenu);
              setMenuEl(menuRef ? menuRef.current : event.target);
            }}
            className="header-menu-icon"
          >
            <MenuIcon />
          </IconButton>
          <div className="menu-ref" ref={menuRef}></div>
          <Menu
            className="header-nav-menu"
            keepMounted
            open={showMenu}
            onClose={handleCloseMenu}
            anchorEl={menuEl}
          >
            <div className="close-button-wrapper">
              <IconButton
                aria-label="close"
                onClick={handleCloseMenu}
                className="header-menu-icon"
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className="link-wrapper">
              <Link
                to="/"
                className={`${activeLink === "" ? "active" : ""}`}
                onClick={() => setActiveLink("")}
              >
                Home
              </Link>
            </div>
            <div className="link-wrapper">
              <a href="https://login.softspot.ai" target="_blank">
                SoftSpot&trade;
              </a>
            </div>
            <div className="link-wrapper">
              <a href="https://docs.google.com/document/d/1_mKfOpoMuit-5Hs60d7oUIt-WgONiRPPNNzBkEkhkR8/edit" target="_blank">Publications</a>
            </div>
            <div class="dropdown link-wrapper">
              <Link
                to="/"
                className={`${activeLink === "parent-help-center" || activeLink === "physician-help-center" ? "active" : ""}`}
                onClick={() => setActiveLink("")}
              >Help Center</Link>
              <div class="dropdown-content">
                <Link
                  to="/parent-help-center"
                  className={`${activeLink === "parent-help-center" ? "active" : ""}`}
                  onClick={() => setActiveLink("parent-help-center")}
                >
                  Parents
                </Link>
                <Link
                  to="/physician-help-center"
                  className={`${activeLink === "physician-help-center" ? "active" : ""}`}
                  onClick={() => setActiveLink("physician-help-center")}
                >
                  Physicians
                </Link>
              </div>
            </div>
            <br />
            <div class="dropdown link-wrapper">
              <Link
                to="/"
                className={`${activeLink === "news" || activeLink === "contact-us" ? "active" : ""}`}
                onClick={() => setActiveLink("")}
              >About Us</Link>
              <div class="dropdown-content">
                <Link
                  to="/"
                  onClick={() => {
                    setActiveLink("");
                    setTimeout(() => {
                      executeScroll();
                    }, 400);
                  }}
                >
                  Team
                </Link>
                <Link
                  to="/news"
                  className={`${activeLink === "news" ? "active" : ""}`}
                  onClick={() => setActiveLink("news")}
                >
                  News
                </Link>
                <Link
                  to="/contact-us"
                  className={`${activeLink === "contact-us" ? "active" : ""}`}
                  onClick={() => setActiveLink("contact-us")}
                >
                  Contact Us
                </Link>
                <Link
                  to="/contact-us"
                  className={`${activeLink === "contact-us" ? "active" : ""}`}
                  onClick={() => setActiveLink("contact-us")}
                >
                  Quality Policy
                </Link>
              </div>
            </div>
            <br />
            <div class="dropdown link-wrapper">
              <Link
                to="/"
                className={`${activeLink === "shop-mckesson" || activeLink === "shop-henry-schein" ? "active" : ""}`}
                onClick={() => setActiveLink("")}
              >Shop</Link>
              <div class="dropdown-content">
                <a href="https://mms.mckesson.com/product/1223079/Florida-Medical-Sales-PED-SOFTSPOT10" target="_blank">McKesson</a>
                <a href="https://www.henryschein.com/us-en/medical/p/equipment/diagnostic-tools/pediatric-kit-softspot/1434234" target="_blank">                  Henry Schein
                </a>
              </div>
            </div>

            <div className="link-wrapper">
              <div>
                <Button
                  text="Sign In"
                  additionalClass="header-secondary-button"
                  onClick={() => {
                    window.open("https://login.softspot.ai/", "_blank");
                  }}
                />
              </div>
              <div>
                <Button
                  text="Sign Up"
                  additionalClass="header-button"
                  onClick={() => {
                    window.open("https://login.softspot.ai/sign-up", "_blank");
                  }}
                />
              </div>
            </div>
          </Menu>
        </div>
      </div>
    </Grid >
  );
};

export default Header;
