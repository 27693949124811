import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import Grid from "@material-ui/core/Grid";
import { IMG_PM_CONTACTUS, IMG_FACEBOOK, IMG_LINKEDIN, IMG_YOUTUBE, IMG_TWITTER } from "../../PMConstants/PMImageConstants";
import { validateAllFields } from "../../PMShared/PMCommon/PMUtility";
import Textbox from "../../PMComponents/PMTextbox/PMTextbox";
import "./PMContactUs.scss";
import Button from "../../PMComponents/PMButton/PMButton";
import { appActions } from "../../PMShared/PMRedux/PMAppActionReducer";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Helmet } from "react-helmet";

export const ContactUs = (props) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    first_name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [errors, setErrors] = useState({ email: "", first_name: "" });

  const isLoading = useSelector((state) => {
    return state.appReducer.isLoading || false;
  });
  const isUserQuerySent = useSelector((state) => {
    return state.appReducer.isUserQuerySent;
  });
  const userQueryError = useSelector((state) => {
    return state.appReducer.userQueryError
      ? state.appReducer.userQueryError.message || ""
      : "";
  });

  const onHandleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    let userData = { ...formData };
    userData[name] = value;
    setFormData(userData);
  };

  const handleFormSubmit = (e) => {
    console.log(formData);

    e.preventDefault();

    if (!isLoading) {
      dispatch(appActions.resetEmailSent());

      let requestObject = {
        email: formData.email ? formData.email.trim() : "",
        first_name: formData.first_name ? formData.first_name.trim() : "",
      };

      const errorObj = validateAllFields(requestObject, true);
      if (!errorObj.email && !errorObj.first_name) {
        const message = `Subject: ${formData.subject ? formData.subject.trim() : ""
          }, Message: ${formData.message ? formData.message.trim() : ""}`;
        requestObject = {
          admin: true,
          author: formData.first_name ? formData.first_name.trim() + " with email " + formData.email : "",
          email: formData.email ? formData.email.trim() : "",
          messages: [
            {
              message,
              timestamp: +moment(),
            },
          ],
        };
        console.log(requestObject);

        dispatch(appActions.emailUnseenMessages(requestObject));
      } else {
        setErrors(errorObj);
      }
    }
  };

  useEffect(() => {
    if (isUserQuerySent) {
      setFormData({
        first_name: "",
        email: "",
        subject: "",
        message: "",
      });
    }
  }, [isUserQuerySent]);

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://softspot.ai/news" />
      </Helmet>
      <div className="contactus-wrapper">
        <div className="heading">We want to hear from you</div>
        <Grid container spacing={0} className="contactus-content-wrapper">
          <Grid item xs={12} sm={6}>
            <div className="text-center mb30">
              <img
                className="contactus-image"
                src={IMG_PM_CONTACTUS}
                title=""
                alt=""
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} className="contactus-form-wrapper">
          {isUserQuerySent && (
              <div className="success mt20 text-center">
                Your query has been sent to our customer support.
              </div>
            )}
            <form onSubmit={handleFormSubmit} className="contactus-form">
              <Backdrop open={isLoading} className="backdrop-loader">
                <CircularProgress color="inherit" />
              </Backdrop>
              <div className="contactus-form-field">
                <Textbox
                  name="first_name"
                  placeholder="Name *"
                  onChange={onHandleChange}
                  additionalClass="contactus-input"
                  value={formData.first_name}
                  error={errors.first_name}
                />
              </div>
              <div className="contactus-form-field">
                <Textbox
                  name="email"
                  placeholder="Email *"
                  onChange={onHandleChange}
                  additionalClass="contactus-input"
                  value={formData.email}
                  error={errors.email}
                />
              </div>
              <div className="contactus-form-field">
                <Textbox
                  name="subject"
                  placeholder="Subject"
                  onChange={onHandleChange}
                  additionalClass="contactus-input"
                  value={formData.subject}
                />
              </div>
              <div className="contactus-form-field">
                <Textbox
                  name="message"
                  placeholder="Message"
                  onChange={onHandleChange}
                  additionalClass="contactus-input"
                  value={formData.message}
                  multiline={true}
                />
              </div>
              <div>
                <Button
                  text="Send"
                  additionalClass="contact-us-button"
                  onClick={handleFormSubmit}
                />
              </div>
              <div className="social-block">
                <a href="https://www.facebook.com/pediametrix" target="_blank"
                >
                  <img src={IMG_FACEBOOK} className="social-icon" />
                </a>
                <a href="https://twitter.com/pediametrix_inc" target="_blank"
                >
                  <img src={IMG_TWITTER} className="social-icon" />
                </a>
                <a href="https://www.youtube.com/channel/UCi6sRGe7AWZ4LlDR9Ymzwbw/about/" target="_blank"
                >
                  <img src={IMG_YOUTUBE} className="social-icon" />
                </a>
                <a href="https://www.linkedin.com/company/pediametrix/" target="_blank"
                >
                  <img src={IMG_LINKEDIN} className="social-icon" />
                </a>
              </div>
            </form>
            {userQueryError && <div className="error mt20">{userQueryError}</div>}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ContactUs;
