import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { Player, BigPlayButton } from "video-react";
import { Helmet } from "react-helmet";

import Button from "../../PMComponents/PMButton/PMButton";
import {
  PM_LOGO,
  DEMO_PAGE_IMAGE,
  SHOPPING_CART_IMAGE
} from "../../PMConstants/PMImageConstants";
import "./PMReferralVideo.scss";
import { Link } from "react-router-dom";

const ReferralVideo = (props) => {

  return (
    <>
      <Grid item xs={12}>
        <div className="demo-content-wrapper">
          <div className="player-container">
            <div className="player-wrapper">
              <Player className="react-player" autoPlay={true} muted={true}>
                <source src='videos/referral_code_explanatory.mp4' />
                <BigPlayButton position="center" />
              </Player>
            </div>
          </div>
        </div>
      </Grid>
    </>
  );
};

export default ReferralVideo;