import React, { useState } from "react";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

import "./PMHelpCenter.scss";
import {
    IMG_PM_INSTRUCTION_BABY_HEAD,
    IMG_PM_HC_MEASURE_1,
    IMG_PM_HC_MEASURE_2,
    IMG_PM_HC_MEASURE_3,
    IMG_PM_HC_MEASURE_4,
    IMG_PM_HC_RESULT_1,
    IMG_PM_HC_RESULT_2,
    IMG_PM_HC_ACCURACY,
    IMG_PM_HC_PRODUCT_1,
    IMG_PM_HC_PRODUCT_2,
    IMG_PM_HC_PRODUCT_3,
    IMG_PM_HC_PRODUCT_4,
    IMG_PM_HC_PRODUCT_5,
    IMG_PM_HC_PRODUCT_6,
    IMG_PM_CI_PROSPECTIVE,
    IMG_PM_CI_RETROSPECTIVE,
    IMG_PM_CVAI_PROSPECTIVE,
    IMG_PM_CVAI_RETROSPECTIVE,
} from "../../PMConstants/PMImageConstants";

const PhysicianHelpCenter = () => {
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };
    return (
        <div className="content-wrapper">
            <div className="helpcenter-text">
                <div className="title">Physician Help Center</div>
                <div className="sub-title">All the Physician's FAQs are covered here</div>
            </div>
            <div className="helpcenter-wrapper">
                <Accordion className="helpcenter-accordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className="helpcenter-accordion-title">
                            1. What is the current rate for deformational plagiocephaly and
                            brachycephaly (DPB) in the US?
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="helpcenter-accordion-details">
                            It affects 20-30% of newborn on moderate to severe scale every
                            year in the US{" "}
                            <a
                                className="some-link"
                                target="_blank"
                                href="https://drive.google.com/file/d/12F4qOMuQD7F_JNXdRaDzD3iSOLSdjeG5/view"
                            >
                                Read here
                            </a>
                            .
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion className="helpcenter-accordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className="helpcenter-accordion-title">
                            2. What is AAP recommendation
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="helpcenter-accordion-details">
                            Early identification and active repositioning are the mainstay of
                            therapeutic intervention in children{" "}
                            <a
                                className="some-link"
                                target="_blank"
                                href="https://drive.google.com/file/d/12F4qOMuQD7F_JNXdRaDzD3iSOLSdjeG5/view"
                            >
                                Read here
                            </a>
                            .
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion className="helpcenter-accordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className="helpcenter-accordion-title">
                            3. How does the reimbursement work?
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="helpcenter-accordion-details">
                            <span>
                                Currently, there is not a dedicated CPT code for using SoftSpot. However, you can get reimbursed by using an E&M code of 99213 in combination with the ICD-10-Q67.3 (for plagiocephaly) each time you scan or interpret the results/give recommendations to the patients with SoftSpot. If this happened during a well visit (at month 1,2, 4 or 6), you use a modifier-25 and if not (at month 3 and 5), you can treat it as a sick visit.
                            </span>
                            {/*<ul>
                                <li>
                                    <span>
                                        Currently, there is not a dedicated CPT code for using SoftSpot. However, you can get reimbursed by using an E&M code of 99213 in combination with the ICD-10-Q67.3 (for plagiocephaly) each time you scan or interpret the results/give recommendations to the patients with SoftSpot. If this happened during a well visit (at month 1,2, 4 or 6), you use a modifier-25 and if not (at month 3 and 5), you can treat it as a sick visit.
                                    </span>
                                </li>
                                <li className="mt10">
                                    <span>
                                        In the near future and once Medicaid EPSDT (and
                                        consequently, other insurance plans) covers SoftSpot under
                                        existing CPT codes for well child visits, you can bill per
                                        scan (~$20/scan) for all babies both at sick and well
                                        checkups. This not only include scanning for
                                        plagiocephaly/brachycephaly, but also for head circumference
                                        which is part of every child well visit for the first two
                                        years.
                                    </span>
                                        </li>
                            </ul>*/}
                        </div>
                    </AccordionDetails>
                </Accordion >

                {/*<Accordion className="helpcenter-accordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className="helpcenter-accordion-title">
                            4. How much revenue potential can my practice generate per
                            physician?
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="helpcenter-accordion-details">
                            <span>
                                Assuming you start at month 1 and repeat scans monthly until month 6, you can get reimbursed 6 times. An average $60/scan reimbursement will generate up to $360 in extra revenue for your practice. 
                            </span>
                        </div>
                    </AccordionDetails>
                        </Accordion>*/}

                <Accordion className="helpcenter-accordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className="helpcenter-accordion-title">
                            4. What does SoftSpot measure
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="helpcenter-accordion-details">
                            SoftSpot measures CI and CVAI.
                            <div>Here are the definitions:</div>
                            <table className="mt30 help-center-table text-center mb30">
                                <thead>
                                    <tr>
                                        <th width="75%">Index</th>
                                        <th width="25%">How it is measured</th>
                                        {/*<th>What it could mean</th>*/}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>CI (Cranial Index)</td>
                                        <td>
                                            <img
                                                src={IMG_PM_HC_MEASURE_1}
                                                alt=""
                                                className="image-wrapper"
                                            />
                                            <div> CI = SD/AP ×100</div>
                                        </td>
                                        {/*<td>Brachycephaly/scaphocephaly</td>*/}
                                    </tr>
                                    <tr>
                                        <td>CVAI (Cranial Vault Asymmetry Index) <br />
                                            <span className="table-cell-details">
                                                •AP is defined on the head contour by the line that goes through the nose tip and center of the head contour. <br />

                                                •SD is defined by the line perpendicular at the midpoint of AP and intersecting the head contour. <br />

                                                •AA and BB are defined by the intersection of the head contour and the lines that go through the center of the head contour at +/-40 degrees relative to AP. <br />
                                                A negative CVAI means the left diagonal was shorter (or left flattening) and positive CVAI means the right diagonal was shorter (or right flattening).
                                            </span>
                                        </td>
                                        <td>
                                            <div>
                                                <img
                                                    src={IMG_PM_HC_MEASURE_2}
                                                    alt=""
                                                    className="image-wrapper"
                                                />
                                            </div>
                                            <div>
                                                <img src={IMG_PM_HC_MEASURE_3} alt="" />
                                            </div>
                                        </td>
                                        {/*<td>Plagiocephaly</td>*/}
                                    </tr>
                                    {/*<tr>
                    <td>HC (Head Circumference)</td>
                    <td>
                      <img
                        src={IMG_PM_HC_MEASURE_4}
                        alt=""
                        className="image-wrapper"
                      />
                    </td>
                    <td>Baby’s development</td>
                  </tr>
                  <tr>
                    <td>Craniosynostosis</td>
                    <td>In R&D phase</td>
                    <td>Surgery</td>
                  </tr>*/}
                                </tbody>
                            </table>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion className="helpcenter-accordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className="helpcenter-accordion-title">
                            5. How to interpret the results?
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="helpcenter-accordion-details">
                            <div className="">
                                <div>
                                    <div>
                                        On each report, you see one number for CI and one for CVAI
                                        as follows (in this example, the patient has done 3
                                        measurements):
                                    </div>
                                    <div className="workflow-image-overlap">
                                        <img
                                            src={IMG_PM_HC_RESULT_1}
                                            alt=""
                                            className="image-wrapper fix_width"
                                        />
                                    </div>
                                </div>
                                <div className="second-div">
                                    <div>
                                        For CI and CVAI, in addition to the main measurement, we
                                        report the standard deviation which is the range of
                                        confidence of the accuracy (note: we use multiple pictures
                                        to generate the most accurate results).
                                    </div>
                                    <div className="mt10">
                                        Here is a reference table from Children’s Hospital of Atlanta (CHOA) to assist you on interpretation of results:
                                    </div>
                                    <div className="workflow-image-overlap">
                                        <img
                                            src={IMG_PM_HC_RESULT_2}
                                            alt=""
                                            className="image-wrapper"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion className="helpcenter-accordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className="helpcenter-accordion-title">
                            6. How frequently do I or parents should scan the head?
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="helpcenter-accordion-details">
                            We recommend you start as early as 1 month and repeat every month
                            till 6 month of age.
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion className="helpcenter-accordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className="helpcenter-accordion-title">
                            7. How accurate is SoftSpot?
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="helpcenter-accordion-details">
                            <div>
                                We performed two clinical studies to measure the accuracy of SoftSpot:
                            </div>
                            <br />
                            <div>
                                <span>a.  <b><i>Prospective: </i></b> for the prospective evaluation of our measurement tool, PediaMetrix compared measurements taken with the SoftSpot<sup>TM</sup> app to the measurements taken by clinicians using a standard caliper similar to the predicate device. A comparative study was performed using 25 infants (age range 3 to 12 months). Data were collected by a physical therapist in a plagiocephaly clinic at the University of Pittsburgh Children Hospital from their typical patient population. The Spearman correlation coefficient was 0.96 and 0.94 for CI and CVAI, respectively. The mean absolute error (MAE) and standard deviation (STD) were 1.48 ± 0.92 and 1.44 ± 0.85 units for CI and CVAI, respectively.
                                </span>
                            </div>

                            <div className="">
                                <table className="help-center-table fix-width-table">
                                    <thead>
                                        <tr>
                                            <th>Prospective study (25 patients)</th>
                                            <th>CI</th>
                                            <th>CVAI</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Spearman correlation test (p &lt; 0.001)</td>
                                            <td>0.96</td>
                                            <td>0.94</td>
                                        </tr>
                                        <tr>
                                            <td>Mean Absolute Error (MAE)</td>
                                            <td>1.48</td>
                                            <td>1.44</td>
                                        </tr>
                                        <tr>
                                            <td>STD</td>
                                            <td>0.92</td>
                                            <td>0.85</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="second-div text-center">
                                    <img
                                        src={IMG_PM_CI_PROSPECTIVE}
                                        alt=""
                                        className="image-wrapper"
                                    />
                                    <img
                                        src={IMG_PM_CVAI_PROSPECTIVE}
                                        alt=""
                                        className="image-wrapper"
                                    />
                                    <div className="second-div text-center">                  Figure 1: Modified Bland-Altman comparison of measurements from 2D renders vs clinical ground truth values from caliper for CI (left) and CVAI (right).
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div>
                                <span>b.  <b><i>Retrospective: </i></b> We also proposed to compare SoftSpot measurements of 2D rendered images (from 3D head scans) with direct measurements from 3D scans of the head from patients with cranial deformities in a retrospective study using 100 infants’ head measurements along with their 3D scans (in .stl format). Data were collected from patients aged 3-20 months with deformational plagiocephaly and brachycephaly (DPB) in a helmet clinic at Singapore. The Spearman correlation coefficient was 0.93 and 0.94 for CI and CVAI, respectively. The mean absolute error (MAE) and standard deviation (STD) were 2.17 ± 1.53 and 1.62 ± 1.26 units for CI and CVAI, respectively.
                                </span>
                            </div>
                            <div className="">
                                <table className="help-center-table fix-width-table">
                                    <thead>
                                        <tr>
                                            <th>Retrospective study (100 patients)</th>
                                            <th>CI</th>
                                            <th>CVAI</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Spearman correlation test (p &lt; 0.001)</td>
                                            <td>0.93</td>
                                            <td>0.94</td>
                                        </tr>
                                        <tr>
                                            <td>Mean Absolute Error (MAE)</td>
                                            <td>2.17</td>
                                            <td>1.62</td>
                                        </tr>
                                        <tr>
                                            <td>STD</td>
                                            <td>1.53</td>
                                            <td>1.26</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="second-div text-center">
                                    <img
                                        src={IMG_PM_CI_RETROSPECTIVE}
                                        alt=""
                                        className="image-wrapper"
                                    />
                                    <img
                                        src={IMG_PM_CVAI_RETROSPECTIVE}
                                        alt=""
                                        className="image-wrapper"
                                    />
                                    <div className="second-div text-center">
                                        Figure 2: Modified Bland-Altman comparison of measurements from 2D renders vs clinical ground truth values for a) CI and b) CVAI.                   </div>
                                </div>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion className="helpcenter-accordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className="helpcenter-accordion-title">
                            8. Treatment help (repositioning, online consultation)
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="helpcenter-accordion-details">
                            <div>
                                Treatment for plagiocephaly usually includes special exercises,
                                varying sleep position or wearing corrective headbands or using
                                molding cups. 70-90% of plagiocephaly patients suffer from
                                torticollis (stiffness in the neck muscles){" "}
                                <a
                                    className="some-link"
                                    target="_blank"
                                    href="https://europepmc.org/article/med/25415272"
                                >
                                    Read here
                                </a>{" "}
                                which may require physical therapy.
                            </div>
                            <div className="mt10">
                                Here is a comprehensive reference from Children’s’ Hospital of
                                Atlanta:
                            </div>
                            <div className="mt10">
                                <a
                                    className="some-link"
                                    target="_blank"
                                    href="/images/tummy-time-tools-english.pdf"
                                >
                                    Click here                                </a>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion className="helpcenter-accordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className="helpcenter-accordion-title">
                            9. Other products for conservative management of plagiocephaly:
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="helpcenter-accordion-details product-info">
                            {/*<div className="text-img-wrapper">
                                <div className="img-container">
                                    <img src={IMG_PM_HC_PRODUCT_1} alt="" className="fix_width" />
                                </div>
                                <div className="content-wrapper">
                                    <span className="text-title">
                                        <a
                                            className="some-link"
                                            target="_blank"
                                            href="https://buy.stripe.com/28oaI8ee4buG2aY3cf"
                                        >
                                            Cap
                                        </a>
                                    </span>
                                    : Required for all babies

                                </div>
                                        </div>*/}
                            {/*<div className="text-img-wrapper">
                                <div className="img-container">
                                    <img src={IMG_PM_HC_PRODUCT_1} alt="" className="fix_width" />
                                </div>
                                <div>
                                    <span className="text-title">
                                        <a
                                            className="some-link"
                                            target="_blank"
                                            href="https://buy.stripe.com/28oaI8ee4buG2aY3cf"
                                        >
                                            Cap

                                        </a>
                                    </span>
                                    : SoftSpot Patient Scans

                                </div>
                            </div>
                            <div className="text-img-wrapper">
                                <div className="img-container">
                                    <img src={IMG_PM_HC_PRODUCT_2} alt="" className="fix_width" />
                                </div>
                                <div>
                                    <span className="text-title">
                                        <a
                                            className="some-link"
                                            target="_blank"
                                            href="https://www.amazon.com/Babymoov-Lovenest-Patented-Syndrome-Prevention/dp/B06XSFV4R6/ref=sr_1_2?dchild=1&keywords=plagiocephaly+pillow&qid=1603070050&sr=8-2"
                                        >
                                            Plagiocephaly Pillows
                                        </a>
                                    </span>
                                </div>
                                    </div>*/}
                            <div className="text-img-wrapper">
                                <div className="img-container">
                                    <img src={IMG_PM_HC_PRODUCT_3} alt="" className="fix_width" />
                                </div>
                                <div>
                                    <span className="text-title">
                                        <a
                                            className="some-link"
                                            target="_blank"
                                            href="https://www.amazon.com/Tortle-Elephant-Prevents-Syndrome-Torticollis/dp/B078J46NNQ/ref=sr_1_1?dchild=1&keywords=tortle&qid=1603070133&sr=8-1"
                                        >
                                            Tortle
                                        </a>
                                    </span>
                                    : Adjustable Repositioning Beanie
                                </div>
                            </div>

                            {/*<div className="text-img-wrapper">
                                <div className="img-container">
                                    <img src={IMG_PM_HC_PRODUCT_4} alt="" className="fix_width" />
                                </div>
                                <div>
                                    <span className="text-title">
                                        <a
                                            className="some-link"
                                            target="_blank"
                                            href="https://www.amazon.com/Ubimed-Lifenest-Sleep-System-White/dp/B00D5DQ3OQ/ref=sr_1_9?dchild=1&keywords=plagiocephaly+mattress&qid=1603070314&sr=8-9"
                                        >
                                            Plagiocephaly Mattresses
                                        </a>
                                    </span>
                                </div>
                                </div>*/}
                            <div className="text-img-wrapper">
                                <div className="img-container">
                                    <img src={IMG_PM_HC_PRODUCT_5} alt="" className="fix_width" />
                                </div>
                                <div>
                                    <span className="text-title">
                                        Flat Head Syndrome Fix (
                                        <a
                                            className="some-link"
                                            target="_blank"
                                            href="https://www.amazon.com/Flat-Head-Syndrome-Fix-Plagiocephaly-ebook/dp/B017UPRUXW/ref=sr_1_2?dchild=1&keywords=plagiocephaly+book&qid=1603070605&sr=8-2"
                                        >
                                            Book
                                        </a>
                                        )
                                    </span>
                                </div>
                            </div>
                            <div className="text-img-wrapper mb20">
                                <div className="img-container">
                                    <img src={IMG_PM_HC_PRODUCT_6} alt="" className="fix_width" />
                                </div>
                                <div>
                                    <span className="text-title">
                                        Perfect Noggin Mattress (
                                        <a
                                            className="some-link"
                                            target="_blank"
                                            href="https://www.theperfectnoggin.com/shop-1"
                                        >
                                            Click Here
                                        </a>
                                        )
                                    </span>
                                </div>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion className="helpcenter-accordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className="helpcenter-accordion-title">10. Helmet therapy</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="helpcenter-accordion-details">
                            Studies suggest that almost 80% of plagiocephaly patients can
                            avoid helmet but only if they diagnose and monitor it early and
                            parent stick to the conservative treatment{" "}
                            <a
                                className="some-link"
                                target="_blank"
                                href="https://europepmc.org/article/med/25415272"
                            >
                                Read here
                            </a>
                            . For severe cases or after 6-7 month of age however, helmet
                            therapy can be a viable treatment option. Please consult with your
                            doctor.
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion className="helpcenter-accordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <div className="helpcenter-accordion-title">11. Terms and conditions highlights (please read carefully):</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="helpcenter-accordion-details">
                            <span>

                                <b>Indication(s) for use:</b> <br />

                                SoftSpot™ is intended to be used for cranial measurement for infants from 1 month up to 2 years of age in conjunction with other clinical methods. <br /> <br />

                                <b>Warning:</b> SoftSpot™ is not intended to be used as a tool for preparing a helmet. <br /> <br />

                                {/*<b>Special conditions for use statement(s):</b><br />

                                        For prescription use only. <br /> <br />*/}

                                <b>Device Description:</b><br />

                                SoftSpot™ is a software that runs on a smartphone, tablet, PC, etc. and uses top view pictures of infant’s head to measure width, length, diagonals of the head, and head curvature circumference. After the photo is taken, the head contour is identified and then, based on the current standards of measuring infants’ head shape, characterizing parameters including Cranial Index or CI and Cephalic Vault Asymmetry Index or CVAI are calculated. The SoftSpot™ platform allows different users to upload or capture photos of the infant’s head. The user can be a physician/nurse (during well visits) or parents/guardians. Once data are collected, it will be analyzed and a pdf report is generated with the results and sent to the User's account. Users will be notified when the results are ready. Then, the parent can visit the physician (either in person or online) to discuss the results and next steps.
                            </span>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div >
        </div >
    );
};

export default PhysicianHelpCenter;
