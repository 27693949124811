import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Helmet } from "react-helmet";

import Button from "../../PMComponents/PMButton/PMButton";
import {
  PM_LOGO,
  DEMO_PAGE_IMAGE,
  BUY_IMAGE
} from "../../PMConstants/PMImageConstants";
import "./PMParent.scss";
import { Link } from "react-router-dom";

const Parent = (props) => {

  return (
    <>
      <Helmet>
        {/*Start of HubSpot Embed Code */}
        <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/3289469.js"></script>
        {/* End of HubSpot Embed Code */}
        {/*Start of Zendesk Embed Code */}
        <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=19450bbc-87ed-4529-aa07-a8c708ef478c">
        </script>
        {/* End of Zendesk Embed Code */}
      </Helmet>
      <Grid container spacing={0} className="demo-dashboard-container">
        <Grid item xs={12}>
          <div className="header-wrapper">
            <div>
              <a
                href="https://softspot.ai"
                target="_blank"
              >
                <img className="" src={PM_LOGO} title="" alt="Softspot App For Cranial Measurements" />
              </a>
            </div>
          </div>
        </Grid>
        <Grid item xs={24} sm={12}>
          <div className="demo-content-wrapper">
            <div className="demo-title">
              Would you like to measure your baby’s head at home?
            </div>
            <div className="subheadline">
              Get reassured with an FDA-cleared cranial evaluation technology that helps you measure and monitor your baby’s head shape at-home.
            </div>
            {/*<div className="player-container">
              <div className="player-wrapper">
                <Player className="react-player" autoPlay={true} muted={true}>
                  <source src='videos/soft_spot_demo_surgeons.mp4' />
                  <BigPlayButton position="center" />
                </Player>
              </div>
  </div>*/}
            <div className="demo-image-wrapper">
              <img
                className="demo-dashboard-mission-image"
                src={DEMO_PAGE_IMAGE}
                title=""
                alt="Softspot App For Cranial Measurements"
              />
            </div>
            <div className="text-left">
              <div className="overview">
                Overview of benefits: </div>
              <ul className="overview-points">
                <li> &nbsp; Catch head deformity early</li>
                <li> &nbsp; Monitor the improvement every month</li>
                <li> &nbsp; Easily scan your baby’s head at home &amp; seamlessly share it with your pediatrician</li>
                <li> &nbsp; Save the time it takes for you to go to a specialist’s office</li>
                <li> &nbsp; Save thousands of $$ out-of-pocket for a helmet </li>
                <li> &nbsp; Get reassured about your baby’s head shape</li>
              </ul>
            </div>
          </div>
        </Grid>
        <Grid item xs={24} sm={12} className="">
          <div className="demo-content-wrapper">
            <div className="offer-demo-title">Offer</div>
            <div className="subheadline">
              Each SoftSpot kits include 2 stocking caps, instructions, and allows 3x scans (once a month). <br />
              Use <b className="discount-text">PN2022 referral code for 46% discount</b>:             </div>
            <a href="https://buy.stripe.com/28oaI8ee4buG2aY3cf" target="_blank">
              <img src={BUY_IMAGE} className="buy-img" alt="Softspot App For Cranial Measurements" />
            </a>
          </div>
        </Grid>
        <Grid item xs={24} sm={12} className="">
          <div className="demo-content-wrapper">
            <div className="text-left">
              <div className="overview">
                Workflow: </div>
              <ol className="workflow">
                <li> &nbsp; Purchase a SoftSpot kit</li>
                <li> &nbsp; The kit is shipped to your address (2-3 business days)</li>
                <li> &nbsp; Signup for SoftSpot (use the unique code on the back of the kit)</li>
                <li> &nbsp; Scan your baby’s head (takes &lt; 5 min)</li>
                <li> &nbsp; We send your report (within a business day) to either: <br></br>
                  - your physician’s account (they need to have signed up for free) <br></br>
                  - a doctor in SoftSpot platform (extra $10/scan)
                </li>
                <li> &nbsp; Repeat every month</li>
              </ol>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Parent;