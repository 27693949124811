import React, { useState, useEffect } from "react";
import { Player, BigPlayButton } from "video-react";
import Grid from "@material-ui/core/Grid";
import "./PMNews.scss";
import { Helmet } from "react-helmet";

export const News = (props) => {
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    fetch(`data/news.json`)
      .then((r) => r.json())
      .then((data) => {
        setNewsData(data.newsList);
      });
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://softspot.ai/news" />
      </Helmet>
      <Grid container spacing={0} className="news-wrapper">
        <div className="heading">News to keep you updated</div>
        <div className="news-content-wrapper">
          {newsData.map((obj, index) => {
            return (
              <div className="news-content-container">
                <div className="img-container">
                  {obj.isVideo && (
                    <Player fluid={false} width={348} height={185} muted={true}>
                      <source src={obj.videoLink} />
                      <BigPlayButton position="center" />
                    </Player>
                  )}
                  {!obj.isVideo && <img src={obj.imageLink} alt="Softspot App For Cranial Measurements" />}
                </div>
                <div className="content-div">
                  <div className="title">{obj.date}</div>
                  <div className="subtitle">
                    <a
                      href={obj.articleLink}
                      target="_blank"
                      title={obj.title || ""}
                    >
                      {obj.title}
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Grid>
    </>
  );
};

export default News;
